import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import {motion} from "framer-motion";

const IconCircle = ({icon, type, size, ...props}) => {
    return (
        <motion.i className={classNames(`icon-circle icon-${icon}`,
            {
                'icon-circle--green': type === 'green',
                'icon-circle--pink': type === 'pink',
                'icon-circle--aqua': type === 'aqua',
                'icon-circle--yellow': type === 'yellow',
                'icon-circle--big': size === 'big',
            }
        )} {...props}></motion.i>
    )
}

IconCircle.propTypes = {
    icon: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
}

export default IconCircle;