import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import {motion} from "framer-motion";

const MessageBox = ({left, className, middle, right, type, size, ...props}) => {
    return (
        <div className={classNames('message-box', className,
            {
                'message-box--only': type === 'only',
                'message-box--small': size === 'small',
            }
        )} {...props}>
            <div className="message-box-wrapper">
                { left && <div className="message-box__left">
                    {left}
                </div> }
                <div className="message-box__middle">
                    {middle}
                </div>
                { right && <div className="message-box__right">
                    {right}
                </div>}
            </div>
        </div>
    )
}

MessageBox.propTypes = {
    left: PropTypes.element,
    className: PropTypes.string,
    middle: PropTypes.element,
    right: PropTypes.element,
    type: PropTypes.string,
    size: PropTypes.string
}

export default MessageBox;