import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, ButtonLink, Input} from "../index";
import classNames from "classnames";
import {MenuLinks} from "../../utilities/DummyContent";
import useMatchMedia from '../../utilities/Functions/useMatchMedia';
import {memo} from "react";
import QRModal from '../Modals/QRModal';


const Header = ({type}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mobileActive, setMobileActive] = useState(false)
    const [isMobile] = useMatchMedia(1248);
    const [isPhone, setIsPhone] = useState(null)
    const ref = useRef()

    useEffect(() => {
        if (!isMobile && mobileActive) {
            setMobileActive(false)
        }
    }, [mobileActive, isMobile])


    const getOS = () => {
        let userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        if (iosPlatforms.indexOf(platform) !== -1) {
            setIsPhone('https://apps.apple.com/tr/app/papel-türkiye/id1643288351')
        } else if (/Android/.test(userAgent)) {
            setIsPhone('https://play.google.com/store/apps/details?id=com.papelmobile')
        } else {
            setIsPhone(null)
        }
    }

    useEffect(() => {
        getOS()
    }, [])


    return (
        <div id="header" ref={ref}
             className={classNames('header', {'header--white': type === 'white'}, ` ${mobileActive ? "header--active" : "header--passive"}`)}>
            <div className="container">
                <Link className="header__logo" to="/">
                    <figure>
                        <img
                            src={type === 'white' && !mobileActive ? 'images/papel-logo-black.png' : 'images/papel-logo-white.png'}
                            srcSet={type === 'white' && !mobileActive ? 'images/papel-logo-black@2x.png 2x, images/papel-logo-black.png 1x' : 'images/papel-logo-white@2x.png 2x, images/papel-logo-white.png 1x'}
                            alt="Logo"/>
                    </figure>
                </Link>
                <ul className="header__menu">
                    {isMobile && <div className="search-bar">
                        <Input compType="v3" placeholder="Search" iconLeft={<i className='icon-search'></i>}/>
                    </div>}
                    {MenuLinks.map((item, key) => (
                        <Link to={item.href} key={`nav-link-${key}`}>
                            <li className="text--decorative-3">{item.title}</li>

                            {isMobile && <i className='icon-arrow-right-line'></i>}

                        </Link>
                    ))}
                </ul>
                <div className="header__right">
                    <Link
                        className={`header__right__language-btn ${type === "white" && 'header__right__language-btn--white'}`}
                        to="https://papel.com.tr">
                        <i className='icon-earth'></i> TR
                    </Link>
                    <div className='header__right__btn-group'>
                        {
                            isMobile && isPhone ? <ButtonLink
                                    href={isPhone}
                                    type="gradient-btn"
                                    size={mobileActive ? "big" : "small"}
                                    iconLeft={<i className="icon-smartphone"></i>}>
                                    {mobileActive ? "Get the App" : !mobileActive && isMobile ? "App" : "Get the App"}
                                </ButtonLink> :
                                <Button
                                    onClick={() => {
                                        setIsModalOpen(true)
                                    }}
                                    type="gradient-btn"
                                    size={mobileActive ? "big" : "small"}
                                    iconLeft={<i className="icon-smartphone"></i>}>
                                    {mobileActive ? "Get the App" : !mobileActive && isMobile ? "App" : "Get the App"}
                                </Button>
                        }
                        {isMobile && <Button className="button-hamburger"
                                             onClick={() => {
                                                 setMobileActive(!mobileActive)
                                             }}
                                             onlyIcon={true}
                                             iconLeft={<i
                                                 className={mobileActive ? "icon-close" : "icon-mobile-menu"}></i>}>
                        </Button>}
                    </div>
                    {isMobile && <>
                        <div className="header__right--icon-group">
                            <Link target="_blank" to="https://instagram.com/papelturkiye?igshid=YmMyMTA2M2Y="><i
                                className="icon-instagram"></i></Link>
                            <Link target="_blank" to="https://twitter.com/papelturkiye"><i className="icon-twitter"></i></Link>
                            <Link target="_blank" to="https://www.linkedin.com/company/papeltürkiye/"><i
                                className="icon-linkedin"></i></Link>
                        </div>
                        <span
                            className="text--caption copyright">© Copyright 2023 Papel - A new way of money management</span>
                    </>}
                </div>
            </div>
            <QRModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        </div>
    )
}

export default memo(Header);