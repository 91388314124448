import React from 'react';
import PropTypes from "prop-types";

import classNames from 'classnames';

const Input = ({type = 'text', compType, iconLeft, iconRight, ...props}) => {

    return (
        <div className={classNames('input',
            {
                'input--icon-left' : iconLeft,
                'input--v2' : compType === 'v2',
                'input--v3' : compType === 'v3'
            }
        )}>
            { iconLeft && iconLeft }
            <input {...props} type={type}/>
            { iconRight && iconRight }
        </div>
    )
}

Input.propTypes = {
    type: PropTypes.string,
    compType: PropTypes.string,
    iconLeft: PropTypes.element,
    iconRight: PropTypes.element,
    name: PropTypes.string
};

Input.defaultProps = {
    type: "text"
}

export default Input