import {  Tabs } from "antd";
import React, { useState } from "react";
import { Footer, Header, SectionQuestion, Select } from "../../components";
import "./policies.scss";
import useMatchMedia from "../../utilities/Functions/useMatchMedia";

const Policies = () => {
  const [isMobile] = useMatchMedia();
  const [selectValue,setSelectValue] = useState("1")

  
  return (
    <>
      <Header type="white" />
      <section className="section-policies">
        <div className="container">
          {
            isMobile && <Select
            // prefixIcon={<i className="icon-earth"></i>}
            afterfixIcon={<i className="icon-arrow-down"></i>}
            placeholder="Seçiniz"
            className="select--v1"
            onChange={(e)=>{setSelectValue(e)}}
            data={[
                {
                    value: "1",
                    label: 'User Agreements'
                },
                {
                    value: "2",
                    label: 'Privacy Policy'
                },
                {
                    value: "3",
                    label: 'Information Security'
                },
                {
                    value: "4",
                    label: 'Cookies'
                },
                {
                    value: "5",
                    label: 'Security Notification'
                },
                {
                    value: "6",
                    label: 'K.V.K.K.'
                },
            ]}
            defaultValue={selectValue}
        />
          }
         
          <Tabs defaultValue={selectValue} tabPosition="left" className="tab" onTabClick={(key)=>{setSelectValue(key)}} activeKey={selectValue}  >
            <Tabs.TabPane
              tab={<span className="text--h6 text--left">User Agreements</span>}
              key="1"
              
            >
              <h1 className="text--h3 text--dark policies-margin-b">
                User Agreements
              </h1>
              <h1>User Agreements</h1>
              <div className="body">
                <div className="text--p text--dark policies-margin-b">
                  <p>
                    PAPEL ELEKTRONİK PARA VE ÖDEME HİZMETLERİ A.Ş. <br />
                    ÇERÇEVE E-PARA İHRACI VE ÖDEME HİZMETİ SÖZLEŞMESİ
                    <br />
                    Yürürlülük Tarihi: XX XX 20XX
                  </p>

                  <p>
                    1. TARAFLAR İşbu Çerçeve E- Para İhracı ve Ödeme Hizmeti
                    Sözleşmesi ("Sözleşme"); aşağıda tanımlı Papel ile Papel
                    Elektronik Para ve Ödeme Hizmetleri Kullanıcısı (Kullanıcı)
                    arasında akdedilmiş bulunmaktadır. İşbu Sözleşme, Kullanıcı
                    tarafından çevrimiçi olarak kabul edilerek yürürlüğe girecek
                    olup, Kullanıcı, Papel elektronik para ve ödeme
                    hizmetlerinin kullanımının işbu Sözleşme ve eklerinde yer
                    alan hüküm ve koşullara tabi olduğunu kabul, beyan ve
                    taahhüt eder. Papel Adres ve İletişim Bilgisi: Adres:
                    Atakent Mh. 241. Sk. Terrace Tema A Blok No:7/223
                    Küçükçekmece - İstanbul Telefon numarası: 0 (850) 241 24 24
                    E-Posta adresi: info@papel.com.tr Papel, ilgili mevzuat
                    uyarınca sözleşmede herhangi bir değişikliğin yürürlüğe
                    girme tarihinden otuz gün önce kullanıcıya e-posta ve/veya
                    SMS üzerinden değişiklik bildiriminde bulunacak ve revize
                    edilmiş sözleşmeyi web sitesinde yayınlayacaktır. İşbu
                    Sözleşme kapsamında Papel ve Kullanıcı ayrı ayrı "Taraf" ve
                    birlikte "Taraflar" olarak anılacaklardır.
                  </p>

                  <p>
                     2. TANIMLAR Alıcı: Ödeme hizmetine konu fonun ulaşması
                    istenen gerçek veya tüzel kişiyi ifade eder. Banka: Türkiye
                    Cumhuriyet Merkez Bankası Elektronik Para: Kullanıcıdan
                    alınan fon karşılığı Papel tarafından ihraç edilen,
                    elektronik olarak saklanan, Papel dışında diğer gerçek ya da
                    tüzel kişiler tarafından kabul edilen ve Kanun’da tanımlanan
                    ödeme işlemlerini gerçekleştirmek için kullanılan parasal
                    değerdir. Fon: Banknot, madeni para, kaydi para veya
                    elektronik paradır. Fona çevirme: Elektronik paranın
                    banknot, madeni para veya kaydî paraya çevrilmesidir.
                    Gönderen: Kendi ödeme hesabından veya ödeme hesabı
                    bulunmaksızın ödeme emri veren gerçek veya tüzel kişidir.
                    Hassas Ödeme Verisi: Ödeme Emri’nin verilmesinde veya kimlik
                    doğrulamasında kullanılan, ele geçirilmesi veya
                    değiştirilmesi halinde dolandırıcılık ya da sahte işlem
                    yapılmasına imkan verebilecek şifre, güvenlik sorusu,
                    sertifika, şifreleme anahtarı ile PIN, kart numarası, son
                    kullanma tarihi, CVV2, CVC2 kodu gibi Ödeme Aracı’na ilişkin
                    kişisel güvenlik bilgileridir. İş Günü: Pazartesi, Salı,
                    Çarşamba, Perşembe, Cuma günleridir. Kanun: 6493 sayılı
                    Ödeme ve Menkul Kıymet Mutabakat Sistemleri, Ödeme
                    Hizmetleri ve Elektronik Para Kuruluşları Hakkında
                    Kanun’dur. Kart: Papel tarafından üretilen ve kullanıcının
                    Papel hesabına erişimini sağlayan, mal ve hizmet alımlarında
                    kullanılabilen veya üzerinde ön ödemeli değer taşınabilen
                    kartları ifade etmektedir. Kart hamili: Kendisine Papel
                    tarafından kart verilen Kullanıcıyı ifade eder. Kişisel
                    güvenlik bilgileri: Ödeme aracı ile işlem gerçekleştirirken
                    kullanılabilecek şifre, son kullanma tarihi, güvenlik
                    numarası gibi ödeme aracını ve ödeme aracı kullanıcısının
                    kimliğini belirleyici bilgileridir. Kurum: Bankacılık
                    Düzenleme ve Denetleme Kurumu’dur. Kullanıcı: Papel
                    tarafından ihraç edilen elektronik parayı satın alan ve
                    ihraç edilmiş elektronik paraları mal veya hizmet alımında
                    ya da Ödeme Hesabından para göndermede kullanan Papel
                    müşterilerini ifade etmektedir. Mevzuat: Kanun ve Kanuna
                    dayanılarak çıkarılan ilgili ikincil düzenlemelerdir. Ödeme
                    Aracı: Kullanıcı tarafından ödeme emri’ni vermek için
                    kullanılan kart, cep telefonu, şifre ve benzeri kişiye özel
                    araçtır. Ödeme Emri: Kullanıcı tarafından ödeme işleminin
                    gerçekleşmesi amacıyla verilen talimattır. Ödeme Hesabı:
                    Kullanıcı adına Papel nezdinde açılan ve ödeme hizmetlerinin
                    sunulmasında kullanılan hesaptır. Ödeme Hizmeti: Papel’in
                    Kanun ve ilgili ikincil mevzuat uyarınca sunmakta olduğu
                    ödeme hizmetleridir. Papel: Şirket merkezi Atakent Mh. 241.
                    Sk. Terrace Tema No:7/223 Küçükçekmece - İstanbul adresinde
                    bulunan ödeme hizmeti sağlayıcısı Papel Elektronik Para ve
                    Ödeme Hizmetleri A.Ş.’dir.
                  </p>

                  <p>
                     3. SÖZLEŞME’NİN KONUSU VE KAPSAMI İşbu Sözleşme, Papel
                    tarafından Kanun kapsamında Kurum’dan aldığı faaliyet izini
                    çerçevesinde verilmekte olan Elektronik Para ve Ödeme
                    Hizmetlerinden Kullanıcı’nın, faydalanabilmesine ilişkin
                    koşulları ve Taraflar’ın bu kapsamdaki hak ve
                    yükümlülüklerini düzenlemek üzere akdedilmiştir.
                  </p>

                  <p>
                     4. KAYIT Kullanıcı’ların Papel hizmetlerinden yararlanması,
                    Papel ile işbu Sözleşme’nin ve onun ayrılmaz bir parçası
                    olan eklerini akdetmesi ve Kullanıcı’ların sürekli ödeme
                    hizmetlerinden yararlanırken ödeme emri’ni ödeme aracı
                    kullanarak vermeleri koşuluna bağlıdır. Sözleşme
                    akdedilmeden önce ve ayrıca işbu Sözleşme’nin yürürlük
                    süresi içinde, Papel, Kullanıcı’ların usulüne uygun ödeme
                    başvurularını Papel’in belirleyeceği şekilde alma, bunları
                    sonuçlandırma ve ödeme araçlarını sahiplerine teslim etme
                    yetkilerine sahip olacaktır.
                  </p>

                  <p>
                     5. KULLANICI’ NIN SUNMASI GEREKEN BİLGİLER Ödeme
                    işlemlerinin gerçekleştirilmesi için Kullanıcı tarafından
                    sunulması gereken bilgilere ödeme ara yüzü üzerinden
                    erişilmesi mümkün olup Kullanıcı bu bilgileri kendisi
                    dolduracaklardır. Bu kapsamda Kullanıcı, yararlanılan ödeme
                    hizmetine göre ilgili alanlara manuel olarak veri girişi
                    yaparak Kullanıcı tarafından bilinebilecek bilgileri ayrıca
                    belirtecektir.
                  </p>
                  <p>
                     6. PAPEL ELEKTRONİK PARA İHRACI VE KULLANIMINA İLİŞKİN
                    DÜZENLEMELER 6.1 Kullanıcı tarafından Elektronik Para ihracı
                    talebinde bulunulması halinde Papel, Kullanıcı tarafından
                    tanımlı kredi kartı, banka kartı veya banka hesabı üzerinden
                    iletilen fon kadar Elektronik Parayı ihraç ederek
                    Kullanıcı’ya sunacaktır. Papel, elektronik para karşılığında
                    aldığı fonun tutarını gösteren dekontu elektronik ortamda
                    Kullanıcı’ya iletecektir. 6.2 Papel, Mevzuat ve diğer
                    kanunların verdiği yetkiler ve koyduğu yükümlülükler saklı
                    kalmak kaydıyla, Kullanıcı’nın talebi üzerine elektronik
                    paranın karşılığı kadar fonun geri ödenmesine ilişkin
                    işlemleri gecikmeksizin, her halde en geç iki iş günü
                    içerisinde yapmakla yükümlüdür. Geri ödeme elektronik
                    paranın fona çevrilmesi suretiyle gerçekleştirilir. 6.3
                    Kullanıcı, herhangi bir zamanda Elektronik Para’nın kısmen
                    veya tamamen fona çevrilmesi talebinde bulunabilecektir.
                    Papel, Mevzuat uyarınca aksi yönde bir hak veya yükümlülüğü
                    bulunmadığı sürece, Kullanıcı’nın talebi üzerine Elektronik
                    Para karşılığı kadar fonun geri ödenmesine ilişkin işlemleri
                    gerçekleştirecektir.
                  </p>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span className="text--h6 text--left">Privacy Policy</span>}
              key="2"
            >
              <span>limits tab</span>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span className="text--h6">Information Security</span>}
              key="3"
            >
              <span>limits tab</span>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span className="text--h6">Cookies</span>}
              key="4"
            >
              <span>limits tab</span>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span className="text--h6">Security Notification</span>}
              key="5"
            >
              <span>limits tab</span>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span className="text--h6">K.V.K.K.</span>}
              key="6"
            >
              <span>limits tab</span>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </section>
      <SectionQuestion />
      <Footer />
    </>
  );
};

export default Policies;
