import React from 'react'
import PropTypes from "prop-types";
import classNames from "classnames";
import {motion} from "framer-motion";

const Button = ({size,btnType, className, iconLeft, iconRight, onlyIcon, center, type, compType = 'button', ...props}) => {
    return (
        <motion.button type={btnType && btnType} className={classNames('button', className, type && `button--${type}`,
            {
                'button--small': size === 'small',
                'button--big': size === 'big',
                'button--center': center,
                'button--left-icon': iconLeft && !iconRight,
                'button--right-icon': iconRight && !iconLeft,
                'button--both-icon': iconRight && iconLeft,
                'button--only-icon': onlyIcon,
            }
        )} {...props}>
            {iconLeft ? iconLeft : null}
            { !onlyIcon && <span>{props.children}</span> }
            {iconRight ? iconRight : null}
        </motion.button>
    )
}

export default Button;

Button.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
    center: PropTypes.bool,
    children: PropTypes.any,
    onlyIcon: PropTypes.bool,
    iconLeft: PropTypes.element,
    iconRight: PropTypes.element,
}



