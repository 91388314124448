import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "antd/dist/antd.min.css";
import './assets/styles/styles.scss';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);

reportWebVitals();
