import {Button, Footer, Header, SectionContact, SectionQuestion} from "../../components";
import React, {useState} from "react";
import {Tabs, Col, Row} from "antd";
import useMatchMedia from '../../utilities/Functions/useMatchMedia'
import "./fees-limits.scss"

const FeesLimits = () => {
    const [mobileTab, setMobileTab ] = useState(1)
    const [isMobile] = useMatchMedia();

    const array = [
        {
            title: 'Account opening and usage fee',
            items: [
                {
                    id: 1,
                    text: 'Ücretsiz'
                },
                {
                    id: 2,
                    text: 'Ücretsiz'
                },
                {
                    id: 3,
                    text: 'Ücretsiz'
                },
            ]
        },
        {
            title: 'Bill payments',
            items: [
                {
                    id: 1,
                    text: 'Ücretsiz'
                },
                {
                    id: 2,
                    text: 'bill'
                },
                {
                    id: 3,
                    text: 'Ücretsiz'
                },
            ]
        },
        {
            title: 'International money transfer',
            items: [
                {
                    id: 1,
                    text: 'Ücretsiz'
                },
                {
                    id: 2,
                    text: 'ss'
                },
                {
                    id: 3,
                    text: 'Ücretsiz'
                },
            ]
        },
    ]

    function onChangeMobileTab(index) {
        isMobile && setMobileTab(index)
    }

    return (
        <>
            <Header type="white"/>
            <section className="section-fees">
                <div className="container">
                    <Row>
                        <Col md={{
                             span:16,
                             offset:4                           
                        }}>
                            <h1 className="text--h2 text-center text--dark">Details that make Papel users
                                privileged.</h1>
                        </Col>
                    </Row>
                    <Tabs className="tab">
                        <Tabs.TabPane
                            tab={<span className="text--h4">Fees</span>}
                            key="1">
                            <div className="table--custom">
                                {isMobile ? <div className="button-group">
                                    <Button type="secondary" onClick={() => onChangeMobileTab(1)}>Metal</Button>
                                    <Button type="secondary" onClick={() => onChangeMobileTab(2)}>Standart</Button>
                                    <Button type="secondary" onClick={() => onChangeMobileTab(3)}>Gold</Button>
                                </div> : <div className="head">
                                    <span>Özellik</span>
                                    <span>Standart</span>
                                    <span>Metal</span>
                                    <span>Gold</span>
                                </div>}
                                <div className="body">
                                    { array.map( (item, key) => (
                                        <div key={`custom-table-item-${key}`} className="text-group">
                                            <span className="text--subtitle title">{item.title}</span>
                                            { isMobile ?
                                                <span className="text--subtitle text">{item?.items?.find((item) => item.id === mobileTab)?.text}</span> :
                                                item?.items?.map( (item, key) =>  (
                                                    <span key={`custom-table-items-${key}`} className="text--subtitle text ">{item.text}</span>
                                                ) )
                                            }
                                        </div>
                                    ) ) }
                                </div>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={<span className="text--h4">Limits</span>}
                            key="2">
                            <span>limits tab</span>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </section>
            <SectionQuestion/>
            <SectionContact/>
            <Footer/>
        </>
    )
}

export default FeesLimits;