import React, { useRef } from "react";
import {
  Modal,
  // notification,
} from "antd";
import { useFormik } from "formik";
// import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import Button from "../../Button";

const RecaptchaModal = ({ isModalOpen, setIsModalOpen, currentForm }) => {
  const reCaptchaRef = useRef(null);

  //   const openNotification = (message, type) => {
  //     notification.open({
  //       message: type === "success" ? "Successful" : "Error",
  //       type: type,
  //       icon: <i className="icon-check"></i>,
  //       placement: "topRight",
  //     });
  //   };

  const formik = useFormik({
    initialValues: {
      recaptcha: "",
    },
    validationSchema: yup.object().shape({
      recaptcha: yup
        .string()
        .min(1, "Prove You Are Not A Robot")
        .required("Prove You Are Not A Robot"),
    }),
    onSubmit: async (values) => {
      //   await emailjs
      //     .sendForm(
      //       "service_283rh1a",
      //       "template_xkhsc97",
      //       currentForm.form,
      //       "qATq4ouiwvSEBpe9Z"
      //     )
      //     .then(
      //       (result) => {
      //         console.log("nee", result.text);
      //         openNotification("Success", "success");
      //       },
      //       (error) => {
      //         console.log(error.text);
      //         openNotification({ message: "Failed", type: "error" });
      //       }
      //     );
      await currentForm.resetFonk();
      setIsModalOpen(false);

      console.log(currentForm.form);
    },
  });
  return (
    <>
      <Modal
        open={isModalOpen}
        className="ant-modal--recaptcha"
        centered={true}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        closeIcon={<i className="icon-close"></i>}
        footer={false}
      >
        <h2 className="text--h5 text--dark">Prove you are not a robot!</h2>
        <form onSubmit={formik.handleSubmit}>
          <ReCAPTCHA
            className="recaptcha"
            value={formik.values.recaptcha}
            size="normal"
            name="recaptcha"
            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // test code
            // sitekey="6Le3kH8lAAAAAPpj_aQx9gnCOYy9XlEPtQy9lftE" // papel.com
            sitekey="6Le6mIMlAAAAALSg3eGkjx3bVjazdNzSawMQuVfu"  // dev env
            onChange={async () => {
              const token = await reCaptchaRef.current.getValue();
              formik.setFieldValue("recaptcha", token);
            }}
            ref={reCaptchaRef}
          />
          {formik.errors.recaptcha && formik.touched.recaptcha && (
            <p className="error">{formik.errors.recaptcha}</p>
          )}
          <Button
            btnType="submit"
            type="dark-bordered"
            size="small"
            iconRight={<i className="icon-arrow-right-line"></i>}
          >
            Send
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default RecaptchaModal;
