import React from "react";
import PropTypes from "prop-types";

const HelpBox = ({ image, title }) => {
    return (
        <div className="help-box">
            <figure className="help-box__image">
                <img src={image} alt={title}/>
            </figure>
            <span className="help-box__text text--p">{title}</span>
        </div>
    )
}

HelpBox.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string
}

export default HelpBox;