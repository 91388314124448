import {
  Button,
  Footer,
  Header,
  SectionContact,
  SectionQuestion,
  RangeSlider,
} from "../../components";
import React, { useState } from "react";
import { Col, Row } from "antd";
import { motion } from "framer-motion";
import "./cards.scss";
import classNames from "classnames";
import useMatchMedia from "../../utilities/Functions/useMatchMedia";
import {
  textVariants,
  phoneTextVariants,
  phoneTextEvenVariants,
  globalVariants,
  chatMessageVariants,
  phoneNotificationVariants,
  posCardVariants
} from "../../utilities/Config";
// import VerticalTextScroll from "../../utilities/Functions/verticalTextScroll";

const Cards = () => {
  const [mobileTab, setMobileTab] = useState(1);
  const [isTabletMax] = useMatchMedia(1248);
  // const verticalTextAnimation = VerticalTextScroll();

  const arrayTable = [
    {
      title: "Airline Credit",
      items: [
        {
          id: 1,
          text: "$100 Annual Airline Credit Toward Qualifying Airline Purchases",
        },
        {
          id: 2,
          text: "----",
        },
        {
          id: 3,
          text: "$100 Annual Airline Credit Toward Qualifying Airline Purchases",
        },
      ],
    },
    {
      title: "Global Entry Credit",
      items: [
        {
          id: 1,
          text: "Priority Pass™ Select",
        },
        {
          id: 2,
          text: "----",
        },
        {
          id: 3,
          text: "Priority Pass™ Select",
        },
      ],
    },
    {
      title: "Priority Pass",
      items: [
        {
          id: 1,
          text: "Priority Pass™ Select",
        },
        {
          id: 2,
          text: "Priority Pass™ Select",
        },
        {
          id: 3,
          text: "Priority Pass™ Select",
        },
      ],
    },
    {
      title: "Design",
      items: [
        {
          id: 1,
          text: "Standard",
        },
        {
          id: 2,
          text: "Standard",
        },
        {
          id: 3,
          text: "200 different options",
        },
      ],
    },
  ];
  const array = [
    {
      text: "Standard Card",
      value: 1,
    },
    {
      text: "Virtual Card",
      value: 2,
    },
    {
      text: "Metal Card",
      value: 3,
    },
    {
      text: "Gold Card",
      value: 4,
    },
  ];
  const compareCardsButtons = [
    {
      text: "Metal",
      value: 1,
    },
    {
      text: "Standard",
      value: 2,
    },
    {
      text: "Gold",
      value: 3,
    },
  ];
  const compareCards = [
    {
      id: 1,
      img: "images/compare-metal-card.png",
      srcSet:
        "images/compare-metal-card-2x.png 2x, images/compare-metal-card.png 1x",
      alt: "cards",
    },
    {
      id: 2,
      img: "images/compare-standart-card.png",
      srcSet:
        "images/compare-standart-card-2x.png 2x, images/compare-standart-card.png 1x",
      alt: "cards",
      standarts: true,
    },
    {
      id: 3,
      img: "images/compare-gold-card.png",
      srcSet:
        "images/compare-gold-card-2x.png 2x, images/compare-gold-card.png 1x",
      alt: "cards",
    },
  ];

  function onChangeMobileTab(index) {
    isTabletMax && setMobileTab(index);
  }

  /*const onChangeCard = (value) => {
        setCardSelect(value)
    }*/

  return (
    <div>
      <Header type="white" />
      <section className="section-cards">
        <div className="container">
          <Row>
            <Col lg={{ span: 16 }} span={24}>
              <motion.h2
                variants={textVariants}
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                className="text--h2 text-center text--dark"
              >
                Control, quality, high standards: your style
              </motion.h2>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 16 }} span={24}>
              <motion.p
                variants={phoneTextVariants}
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                className="text--h4 text-center text--dark"
              >
                You can create virtual cards, define spending limits and
                organize your family's financial flow.
              </motion.p>
            </Col>
          </Row>
          <motion.div className="papel-card-animation" variants={phoneNotificationVariants}
            initial="hidden"
            viewport={{ once: true, amount: .1 }}
            whileInView="whileInView">
            <motion.figure className="papel-card-white"
              variants={posCardVariants}>
              <img src="images/papel-card-white.png"
                srcSet="images/papel-card-white-2x.png 2x, images/papel-card-white.png 1x"
                alt="Papel Card" />
            </motion.figure>
            <motion.figure className="papel-card-gold"
              variants={posCardVariants}>
              <img src="images/papel-card-gold.png"
                srcSet="images/papel-card-gold-2x.png 2x, images/papel-card-gold.png 1x"
                alt="Papel Card" />
            </motion.figure>
            <motion.figure className="papel-card-black"
              variants={posCardVariants}>
              <img src="images/papel-card-black.png"
                srcSet="images/papel-card-black-2x.png 2x, images/papel-card-black.png 1x"
                alt="Papel Card" />
            </motion.figure>
            <motion.figure className="papel-card-colored"
              variants={posCardVariants}>
              <img src="images/papel-card-colored.png"
                srcSet="images/papel-card-colored-2x.png 2x, images/papel-card-colored.png 1x"
                alt="Papel Card" />
            </motion.figure>
            <motion.figure className="papel-card-shadow"
              variants={posCardVariants}>
              <img src="images/papel-card-shadow.png"
                srcSet="images/papel-card-shadow-2x.png 2x, images/papel-card-shadow.png 1x"
                alt="Papel Card" />
            </motion.figure>
          </motion.div>
          <motion.div
            variants={globalVariants}
            initial="hidden"
            whileInView="whileInViewSecond"
            viewport={{ once: true, amount: 0.1 }}
            className="button-group"
          >
            {array.map((item, key) => (
              <Button
                variants={phoneTextVariants}
                type="secondary"
                key={`card-type-button-${key}`}
              >
                {item.text}
              </Button>
            ))}
          </motion.div>
          <div className="slider-box">
            <motion.h4
              variants={textVariants}
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              className="slider-box__title"
            >
              The Power of Redemption
            </motion.h4>
            <motion.p
              variants={phoneTextEvenVariants}
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              className="slider-box__text"
            >
              Use the slider how much you could redeem?
            </motion.p>
            <RangeSlider onChange={(value) => console.log(value)} />
            <motion.div
              variants={globalVariants}
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              className="input-wrapper"
            >
              <motion.div
                variants={phoneTextVariants}
                className="slider-box__input"
              >
                <div>
                  <span className="slider-box__input__label">
                    Point Balance
                  </span>
                  <span className="slider-box__input__text">$100.00</span>
                </div>
                <figure>
                  <img
                    src="images/cash-back.png"
                    srcSet="images/cash-back@2x.png 2x, images/cash-back.png 1x"
                    alt="Point Balance"
                  />
                </figure>
              </motion.div>
              <motion.div
                variants={phoneTextVariants}
                className="slider-box__input"
              >
                <div>
                  <span className="slider-box__input__label">Airfare</span>
                  <span className="slider-box__input__text">$200.00</span>
                </div>
                <figure>
                  <img
                    src="images/sent-money.png"
                    srcSet="images/sent-money@2x.png 2x, images/sent-money.png 1x"
                    alt="Airfare"
                  />
                </figure>
              </motion.div>
              <motion.div
                variants={phoneTextVariants}
                className="slider-box__input"
              >
                <div>
                  <span className="slider-box__input__label">Cash Back</span>
                  <span className="slider-box__input__text">$100.00</span>
                </div>
                <figure>
                  <img
                    src="images/gift-card.png"
                    srcSet="images/gift-card@2x.png 2x, images/gift-card.png 1x"
                    alt="Cards"
                  />
                </figure>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
      <section className="section-compare">
        <div className="container">
          <div className="section-compare__title">
            <motion.h3
              variants={chatMessageVariants}
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              className="text--h3"
            >
              Compare Papel Cards
            </motion.h3>
          </div>
          <motion.div 
          // style={{ x: verticalTextAnimation }} 
           className="accessory-1">
            <motion.span className="text--xl text--gradient-white-stroke">
              Compare Cards
            </motion.span>
          </motion.div>
          <div className="table--custom--white">
            {isTabletMax && (
              <div className="button-group">
                {compareCardsButtons.map((item, key) => (
                  <Button
                    type="primary--03"
                    key={`card-type-button-${key}`}
                    className={classNames({ active: mobileTab === item.value })}
                    onClick={(e) => onChangeMobileTab(item.value)}
                  >
                    {item.text}
                  </Button>
                ))}
              </div>
            )}
            <div className="body">
              <motion.div
                variants={globalVariants}
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                className="cards-images"
              >
                {!isTabletMax && <div></div>}
                {compareCards.map((item, key) =>
                  isTabletMax ? (
                    mobileTab === item.id && (
                      <div
                        className={item.standarts && "cards-images__standart"}
                      >
                        <motion.figure variants={textVariants}>
                          <img
                            src={item.img}
                            srcSet={item.srcSet}
                            alt={item.alt}
                          />
                        </motion.figure>
                        {item.standarts && (
                          <>
                            <span className="text--caption">
                              $195 for each Authorized User
                            </span>
                            <span className="text--decorative-2">
                              $495 Annual Fee
                            </span>
                          </>
                        )}
                      </div>
                    )
                  ) : (
                    <div className={item.standarts && "cards-images__standart"}>
                      <motion.figure variants={textVariants}>
                        <img
                          src={item.img}
                          srcSet={item.srcSet}
                          alt={item.alt}
                        />
                      </motion.figure>
                      {item.standarts && (
                        <>
                          <span className="text--caption">
                            $195 for each Authorized User
                          </span>
                          <span className="text--decorative-2">
                            $495 Annual Fee
                          </span>
                        </>
                      )}
                    </div>
                  )
                )}
              </motion.div>
              {arrayTable.map((item, key) => (
                <motion.div
                  variants={globalVariants}
                  initial="hidden"
                  viewport={{ once: true, amount: 0.1 }}
                  whileInView="whileInView"
                  key={`custom-table-item-${key}`}
                  className="text-group"
                >
                  <span className="text--caption--small title">
                    {item.title}
                  </span>
                  {isTabletMax ? (
                    <span className="text--p text">
                      {item?.items?.find((item) => item.id === mobileTab)?.text}
                    </span>
                  ) : (
                    item?.items?.map((item, key) => (
                      <motion.span
                        variants={phoneTextVariants}
                        key={`custom-table-items-${key}`}
                        className="text--p text"
                      >
                        {item.text}
                      </motion.span>
                    ))
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <SectionQuestion />
      <SectionContact
        title={<span className="text--h3">Start selling with Papel</span>}
        text={
          <span className="text--caption">
            Subscribe to our email list to receive advice from other business
            owners, support articles, tips from industry experts, and more.
          </span>
        }
        btn="Talk to our Sales team"
      />
      <Footer />
    </div>
  );
};

export default Cards;
