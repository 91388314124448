import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import PropTypes from "prop-types";


const Checkbox = ({ text, ...props}) => {

    return (<div className='checkbox'>
            <AntdCheckbox {...props}>
                { text ? text : false }
            </AntdCheckbox>
        </div>
    )
}

Checkbox.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
    ]),
};

export default Checkbox;
