import { Col, Row } from 'antd'
import React from 'react'
import { Footer, Header, ProductBox, SectionContact } from '../../components'
import useMatchMedia from '../../utilities/Functions/useMatchMedia';
import './security.scss'

const Security = () => {
    const [isMobile] = useMatchMedia();

  return (
    <>
        <Header type="white"/>
        <section className='section-security'>
            <div className='container'>
                <Row className='row-title' gutter={24}>
                    <Col>
                        <h1 className="text--h3 text--dark">Choose <span className="text--gradient-pink">Papel</span> for Fast,{isMobile ? " " : <br/>}
                        Secure Financial Results!
                        </h1> 
                        <h1 className="text--h4 text--dark">
                        At Papel, we understand the importance of security. That's why we prioritize protecting your personal information and finances when you use our financial applications. Rest assured that your security is our top concern.
                        </h1>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={12}>
                        <ProductBox     type={false}
                                        boxType="light"
                                        className="product-box--cashback"
                                        title={<span className='text--gradient-v4 text--h3'>Your card, your spending.</span>}
                                        image={<figure><img src="images/card-security.png" srcSet='images/card-security-2x.png 2x, images/card-security.png 1x' alt="product cashback"/></figure>}
                                        icons={[]}>
                            </ProductBox>
                    </Col>
                    <Col md={12}>
                        <ProductBox type={false}
                                        boxType="custom"
                                        icons={[]}
                                        className="product-box--chain"
                                        image={<figure><img src="images/secure-chain.png" srcSet='images/secure-chain-2x.png 2x, images/secure-chain.png 1x' alt="product secure"/></figure>}
                                        title={<><span className="text--gradient-v4">Secure</span> with us, every step of the way.</>}>
                            </ProductBox>
                    </Col>
                </Row>
            </div>
        </section>
                
                <section className='section--control'>
                    <div className='container'>
                    <Row className='row-control' gutter={24}>
                        <Col md={8}>
                        <h1 className='text--h3 text--dark'>Control, safe,
                        high standards, registered</h1>

                        </Col>
                        <Col  md={{ 
                            span:8,
                            offset: 8
                        }}>
                        <p className='text--subtitle text--dark'>Papel offers special advantages for its corporate and individual customers.</p>
                        </Col>
                    </Row>
                    <Row className='row-product-boxes' gutter={[24, 75]}>
                    <Col lg={{span: 10, offset: 6}} md={12} span={24}>
                        <ProductBox type="Instant"
                                    className="product-box--notification"
                                    title="Notification"
                                    image={<img src="images/instant-notification.png" srcSet='images/instant-notification-2x.png 2,x images/instant-notification.png 1x' alt="instant notification"/>}
                                    icons={[]}
                        />
                    </Col>
                    <Col lg={8} md={12} span={24}>
                        <ProductBox compType="v2"
                                    type=""
                                    className="product-box--manage"
                                    title={<span className='text--h4 text--dark'>Manage Your Cards</span>}
                                    image={<figure><img src="images/manage-card.png" srcSet='images/manage-card-2x.png 2x, images/manage-card.png 1x' alt="product exclusive"/></figure>}
                                    icons={[]}/>
                    </Col>
                    <Col lg={8} md={12} span={24}>
                        <ProductBox boxType="pink-v2"
                                    className="product-box--identity"
                                    type=""
                                    title={<p>Identity <br/> Authentication</p>}
                                    icons={[]}
                                    image={<figure><img src="images/touch-id.png" alt="product exclusive"/></figure>}/>
                    </Col>
                    <Col lg={10} md={12} span={24}>
                        <ProductBox boxType="custom"
                                    type="Secure"
                                    className="product-box--steps"
                                    title={<span className="text--gradient-purple">Login steps</span>}
                                    image={<figure><img src="images/login-steps.png" srcSet='images/login-steps-2x.png 2x, images/login-steps.png 1x' alt="product transactions"/></figure>}
                                    icons={[]}/>
                    </Col>
                </Row>
                
            </div>
        </section>
        <SectionContact/>
        <Footer />

    </>
  )
}

export default Security