import React from "react";
import { Modal } from "antd";

const AgreementModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <>
      <Modal
        className="modal"
        open={isModalOpen}
        centered={true}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={false}
        title={[<h1>User Agreements</h1>]}
        closeIcon={<i className="icon-close"></i>}
      >
        ​​​CARD ACCEPTOR AGREEMENT​​ 

This Merchant Agreement(hereinafter referred to as the "Agreement"), on the one hand, ​"Maslak Mah.  Eski Buyukdere Cad.  Link Plaza Blok No: 3-5, Interior Door No: 33 Sarıyer/Istanbul​​     ​”, registered with Istanbul Trade Registry Office​ with registration number 1258703​​     ​ , web address; https://papel.com.tr/ and the phone number 0850 241 2424 ​PAPEL ELEKTRONİK PARA VE ÖDEME HİZMETLERİ A.Ş.​ (hereinafter referred to as “PAPEL”) and on the other side ……………………………………… The web address at the address of ……………, registered with the Istanbul Trade Registry Office with the registration number ………………..; www.…………………. and the phone number of ……………………… (hereinafter referred to as the “​Merchant​”) by reaching an agreement on the following matters. In this Agreement, PAPEL and Merchant shall be referred to as "​Party​" separately and as "​Parties​" together. 

This ​     ​ Agreement​     ​  is a whole with its annexes, and the ​Merchant​​     ​  has accepted the Agreement knowing that the provision of the ​PAPEL Services​​     ​  subject to the Agreement is dependent on the completion of the conditions in the Agreement and its annexes and the information and documents that may be requested by PAPEL within the scope of these conditions. 

​​DEFINITIONS​ 

​​Under this Agreement,​ 

​​​Electronic Money:​ The monetary value to be issued against the ​Fund​​     ​  accepted by PAPEL, to be stored electronically, to be used to perform the payment transactions defined in the Law No. 6493 and accepted as a means of payment by real and legal persons other than PAPEL,​ 

​​​End User:​ Those who purchase the products or services offered by the Merchant by using the services (POS) subject to this Agreement,​ 

​​​End User Payment Service Provider  ​: The End User's GSM operator's independent payment service provider company or End User's bank, which has an agreement with PAPEL regarding money transfer, and which has an agreement with the End User for the transfer of money,​ 

​​​3D Secure​: The system developed by Visa (Verified by Visa), Mastercard (Mastercard SecureCode), Amex (SafeKey) and Union Pay International (Secure Pay), Troy (Secure Pay) and similar Card Payment Systems with the aim of ensuring transaction security and securing both Card Holders and member merchants against various irregularities and fraudulent transactions in online shopping with cards,​ 

​​​Bank(s)​: Domestic and foreign banks, including the contracted banks from which PAPEL supplies POS​ 

​​​Debit Card/​​​     ​​​Credit Card (Card(s))​: Card(s) given to individuals and/or institutions by other domestic or foreign banks and/or electronic money institutions that are included or not included in the Card Payment Systems, allowing shopping or purchasing services from member businesses without making any cash payments,​ 

​​​BKM:​ Bankalararası Kart Merkezi A.Ş.’ni,​ 

​​​EMV:​ The whole rules of chip cards and encrypted payment systems, standards set by Europay, MasterCard International, Visa International,​​​ 

​​​Fund: Banknotes, coins, cash or Electronic Money,​​ 

​​​Converting into Funds: Converting electronic money into banknotes, coins or  deposit money,​​ 

​​​Fund Recipient:​ The real or legal person to whom the Fund subject to the      Payment Transaction​     ​​​ is requested ​​to reach, the ​Merchant​​     ​  in this context, or in case the Merchant is an undertaking (Marketplace) that carries out a virtual commercial market activity, the goods or services it produces or supplies the natural or legal person who markets it in the virtual commercial market,​ 

​​​​​​​​​Card Holder:​ Real or legal person authorized to directly use Visa, Mastercard  / Eurocard, JCB Card Credit Cards, Amex,  Union Pay International, Maestro or  Electron  and similar Bank Cards issued by Card Systems  companies,​ 

​​​Card Payment Systems:​  Other national or international organizations that establish card systems such as MasterCard International, Visa International, BKM, Troy or that the Banks are/will be a direct member of, and that authorizes issuing and accepting cards according to these systems,​ 

​​​POS ​​​￼​​​: ​Transfers all information about sales made with cards to the computers of the Banks, can operate in connection with electricity, telephone and/or ADSL lines, can perform information exchange between Merchant and Banks (such as obtaining authorization, transmitting transaction records) by reading the information on the Card, in electronic environment. private transaction terminal and Virtual POS terminals,​ 

​​​Virtual POS (Virtual POS - V POS):​ It is a special transaction terminal that performs information exchange virtually by transferring the information regarding the sale/donation made with the card to the internet or other information processing environments by the Card Holder, Virtual POS, which is integrated into any application that can work independently of both the website, Mobile Device or platform, with the web service provided by PAPEL, provided by PAPEL from the banks with which PAPEL has a contract in particular, in the context of this Agreement,​ 

​​​Mobile POS: ​POS applications that work with GSM, GPRS and/or NFC technology that allow receiving payments from mobile devices,​ 

​​​Mobile Device: ​Mobile phones, tablets and all portable electronic devices that provide data flow over the internet without being limited to this,​ 

​​​Merchant:  ​Real or legal persons who have signed a Merchant Agreement with PAPEL and are engaged in sales activities for products and services offered via the Internet and Mobile Devices through the website at their own address(s) specified in the Application Form, if any, in e-commerce and/or other electronic media,​ 

​​​​​​​​     ​​​     ​​​​Website:​ The Merchant is the owner and/or manager of the above-mentioned goods and services, where the properties and sales prices of the goods and services offered for sale, as well as payment options and delivery conditions are displayed, WEB/wap site and other electronic media​​  where the goods and/or service sales contract is concluded between the End Users and the Merchant,​ 

​​​Law No. 6493: ​Law No. 6493 on Payment and Securities Settlement Systems, Payment Services and Electronic Money Institutions, adopted on 20.06.2013 and published in the Official Newspaper dated 27.06.2013 and numbered 28690,​ 

​​​Regulation: ​Regulation on Payment Services and Electronic Money Issuance and Payment Service Providers,​ 

​​​Communique: ​ Communique on Information Systems of Payment and Electronic Money Institutions and Data Sharing Services in the Field of Payment Services of Payment Service Providers,​ 

​​​KVKK​​     ​: Law on Protection of Personal Data No. 6698 published in the Official Newspaper dated 07.04.2016 and numbered 29677,​ 

​​​Law No. 5549: ​Law on the Prevention of Laundering Proceeds of Crime, dated 11/10/2006 and numbered 5549​ 

​​​Sensitive Payment Data:​ Password, security question, certificate used by the Merchant's End Users for issuing payment orders or for verifying the End Users' identity, which may enable fraudulent or fraudulent transactions on behalf of End Users if captured or changed, encryption key and personal security information regarding payment instruments such as PIN, card number, expiration date, CVV2, CVC2 code,​ 

​​​​​​​​​Payment Tool:​A personal tool such as card, mobile phone, password and similar used by the Card Holder to give the payment order,​ 

​​​Payment Account: ​ The account opened on behalf of the payment service user (Member Merchant) at PAPEL and used for the execution of the payment transaction, the IBAN information defined in the PAPEL system,​ 

​​​Payment Service: A service that provides services to receive payment from the End User via e-wallet and to send the received payment to the Merchant account when activated with ​POS, ​Credit Card​​​, ​Debit Card​​     ​, other cards, mobile application, website,​ 

​​​PAPEL Services: ​Payment and Electronic Money​ transfer services​ and other services provided ​​by PAPEL to the Merchant listed in ​​Article 3 of this​     ​ Agreement and included in its annexes​​,​ 

​​​Payment ​​​     ​​​​​​​Transaction:​ Carrying out the transactions regarding the payment of the Funds collected from the ​Card Holder​​     ​  pursuant to the Agreement and the Agreement between ​PAPEL​​     ​  and the Card Holder to the Fund Recipient and the return of the ​Funds​​     ​  to the beneficiary or beneficiaries in cases where the transaction cannot be performed or subsequently cancelled,​ 

​​​Virtual POS Software: ​ The service provided by PAPEL, which provides payment infrastructure to member merchants within the scope of electronic commerce, transmits these transactions to banks and other contracted institutions on behalf of member merchants, receives provision and provides ​Credit Card​​     ​  custody services to member merchants in environments with PCI-DSS certificate,​ 

​​​Payment Management Panel:​ The web payment management interface that enables the Merchant to access the PAPEL system, give instructions regarding payment services, make settings regarding the PAPEL system, update and change contact information, perform PAPEL POS Service and other transactions within the scope of the Agreement,​ 

​​​SecurityIncident: ​Violation of the confidentiality, integrity or accessibility of information systems or information processed by these systems, or cases of attempted violation or fraud detection,​ 

​​​ QR Code (QR): ​The code generated from the POS terminal and which enables the transfer of card information to the POS terminal by being read through the mobile application,​ 

​​​Payment by QR Code (QR): ​The form of payment made by reading the paycode generated from the POS terminal via a mobile application,​ 

​​​Recurring Payment: ​ The software that encrypts the card and payment information entered in the collections of the Merchant that accepts regular payments and/or ​ makes installments to the Credit Carda​​     ​ with the instruction of the credit card holder and provides access to the main computer,​ 

​​​Chargeback​: Requesting the refund of the transaction performed by PAPEL by the End User by applying to the bank to which the ​Credit Card​​     ​  is affiliated,​ 

​​​Irregular  Transaction​​​(s)​​​: ​: It refers to similar irregular transactions, including but not limited to illegal, unauthorized, erroneous, suspicious transactions, unrealistic returns, forgery,  ​​​ 

​​breach of trust and fraud.​ 

​​SUBJECT AND PURPOSE OF THE AGREEMENT​ 

​​PAPEL is an Electronic Money Institution that has the necessary operating license with the Central Bank in accordance with the Law No. 6493, and offers ​Electronic Money​​     ​  and payment services within the scope of the Law No. 6493 to which it is subject and the relevant legislation. This Agreement has been concluded in order to regulate the rights and obligations of the Parties regarding the PAPEL Services, which will be submitted to the ​​Merchant by ​PAPEL in accordance​​ with the Law No. 6493​​     ​ and the relevant legislation, and the details ​​of which are specified in article 3.​ 

​​The Application Conditions, which are available on the PAPEL web page (https://papel.com.tr/)  and may be updated by PAPEL from time to time, and the Application Form in ​Annex-1,​ are an integral part of this Agreement as valid as of the signature date of the Agreement. ​​​ 

​​​The Annexes will become an integral part of this Agreement upon signing by the Parties, and in case of any conflict between the Annexes and the provisions of the Agreement, references to the Agreement will be interpreted to include the Annexes to the extent appropriate. ​The provisions in the aforementioned annexes shall apply to matters not regulated in this Agreement.​     ​​​     ​​​     ​​ 

​​​​​​​​​​￼​​​ 

​​PAPEL HİZMETLERİ​ 

A- ÖDEME HİZMETLERİ 

​​GENERAL ​ 

​​With this Agreement, PAPEL provides the payment services ​(“Payment Services”)​ defined within the scope of the Law No. 6493. The parties agreed that PAPEL is not a bank, credit or financial institution and that the service is a payment intermediary service, not a banking service, and that PAPEL acts as a payment intermediary and not as a depository or trustee regarding the payment of End Users.​ 

​​The services to be provided by PAPEL within the scope of this Agreement are listed below, and the Merchant wishes to benefit from the services selected in the Application Form with this Agreement. Payment services that will be in addition to the services in this Agreement may be included in the scope of the Agreement with Supplementary Agreements to be signed by the Parties in addition to the Agreement. ​​​​​ 

​​VIRTUAL POS SERVICE​ 

​​​“Virtual Pos Service”​ means the sales ​made​​     ​  and given to the Merchant by the Virtual POS provided by PAPEL from the contracted banks in e-commerce and/or other electronic media via internet, mobile application and/or Mobile Device. It is the service of providing online payments with the payment services infrastructure and virtual payment solutions within the scope of this Agreement in order to collect the costs of the services.​ 

​​It will be able to make the payments to be made by the End Users through the Virtual POS by placing the Virtual POS to be offered by PAPEL under this Agreement on its own website. Merchant accepts​,​ declares and undertakes to ​use​ the​ Virtual POS​ ​     ​ as stipulated in this Agreement.​ 

​​Terms of Use and Working Principles of Virtual POS Service​ 

​​Virtual POS Service; For End Users who prefer to make their payment with PAPEL, it includes collecting the payment from the End User's payment card via PAPEL and transferring it to the bank accounts of the Merchant.​ 

​​PAPEL​ will provide the necessary technological​​     ​  infrastructure for the Merchant to ​integrate the Virtual POS​​     ​  with the Website and to make the payments for the products and services ordered by the End Users through the Virtual POS.​ 

​​While​ ​using this Virtual Pos Service,​     ​  the Merchant will strictly comply with the working principles set forth in this Agreement and its annexes and in particular the following terms of use, otherwise it will be born in the presence of business partners, contracted organizations and other third parties, especially PAPEL and Banks and accepts, declares and undertakes that it will be ​exclusively​​​ responsible for any damages and penalties that may arise​.​​     ​​ 

​​Password and User Name: Merchant will be authorized to use the Virtual POS Service from the date of delivery of the password and user name for access, following the signing of the Agreement and the completion of the integration by PAPEL.​ 

​​Merchant will carry out electronic transactions exclusively by itself, will take the necessary care and diligence to keep the password, key and certificate information given to it or to be acquired under the Agreement confidential, will not disclose them to any third party / institution or will not allow their use by any third party / institution, on the contrary, it will not be affected by the consequences of the behavior accepts, declares and undertakes that he is responsible.​ 

​​Internet Site: Merchant accepts and undertakes that it will not use the Virtual POS on a website other than the web address(es) it has notified to PAPEL during the application.​ 

​​Transaction Limits: PAPEL has the right to set limits for transactions to be made through its services. Merchant accepts that it is bound by the transaction limits determined by PAPEL and cannot perform transactions above these limits. PAPEL will be able to update the transaction limits at any time by making a notification in accordance with the methods and periods determined in the Agreement.​ 

​​PAPEL will be able to update the transaction limits at any time by making a notification in accordance with the methods and periods determined in the Agreement.​ 

​​Language: It is obligatory to have a Turkish Language Option on the Merchant's ​​Website.​​​ 

​​3D Secure: The Merchant accepts and undertakes to support the 3D Secure system to ensure secure transactions while the Card Holder is shopping over the Internet in the Virtual POS application. Payments to be made from the collection page of the Merchant's Website will be made with the 3D Secure verification method.​ 

​​If the Merchant initiates the transaction with a 3D Secure inquiry and the cardholder performs the transaction by entering​​​     ​ the 3D Secure password, the transaction will be fully 3D Secure and the Merchant will be protected from ​Card Holder​​​ objections due to fraudulent transactions in transactions that are fully 3D Secure. However, Merchant is responsible for objections, feedbacks and Chargebacks for transactions that are not 3D Secure.​ 

​​Merchant also establishes a card usage infrastructure to include 3D Secure or Card Holder authentication technology that meets the security measures of 3D Secure Protocol​​ at least. It takes into account, at a minimum, the provisions of the Payment Card Industry Data Security Standard in data processing, recording or communication.​ 

​​The content required to be sent in accordance with the legislation regarding the payment requested to be mediated through an SMS will be sent to the phone that the Merchant declares to belong to the End User. After the End User approves this content and payment, the payment service will be mediated via 3D Secure method over Virtual POS.​ 

​​Prohibited Activities: The sale of products that are prohibited by legislation and international agreements, products containing chemicals harmful to human health (Annex-2 Forbidden Activities), and over-the-counter drugs as per MasterCard and Visa regulations are prohibited, and the Merchant accepts, declares and undertakes that it will not sell or mediate the sale of these products. Merchant is directly responsible for all kinds of penalties and sanctions that PAPEL is or will be liable to pay due to the Merchant's violation of this obligation, and for damages incurred as a result, and accepts, declares and undertakes to pay the penalty amounts to PAPEL upon the notification to be made by PAPEL.​ 

 

 

​​Recurring Payment Application: ​ 

If ​Recurring Payment service​​​ is provided by PAPEL within the scope of this Agreement, Recurring Payment will work with Virtual POS infrastructure, and all terms and conditions applicable to Virtual POS application are also valid for Recurring Payments. PAPEL can set a monthly transaction limit for Recurring Payment transactions. The infrastructure ​and​ internet access required for the installation of the Recurring Payment Application will be met by the Merchant. 

​​QR CODE (QR) APPLICATION​ 

​​ ​The QR Code (QR) ​​     ​to be created from POS terminals is in compliance with BKM standards and can be read using mobile applications of banks. ​ 

​​Sales, installment sales, point redemption, cancellation and return transactions will be supported via POS terminals with QR codes. ​ 

​​​The QR Code​​     ​  (QR) to be generated from the POS terminals will only be used as the method of transmitting the End User's card information from the End User's Mobile Device to the POS device, and all conditions specified and valid in this Agreement regarding payment by ​Credit Card​​     ​  shall also apply to Payment by DataMatrix.​ 

​​MOBILE POS APPLICATION​ 

​​In the event that PAPEL provides a Mobile POS within the scope of this Agreement, the Member of the Merchant official will log in to the Mobile POS application with his/her Turkis Republic ID Number, mobile phone number and password​​​     ​. ​ 

​​In the application, there will be 2 types of payment options as contactless and ​QR​​     ​  Code payment. In order to make contactless payments, the Merchant's device must be NFC (Near Field Communication) enabled and the ​Credit Card​​     ​ of the customer who will make the payment must be contactless, and for Payment with QR Code (QR)​     ​​,​ the End User must have a mobile application that can read the ​QR Code.​​     ​  ​ 

​​Within the Mobile Device Application, sales, installment sales, point usage, cancellation and refund transactions can be supported. ​ 

​​After the transactions made with the application, the information slip will be sent by sms or e-mail according to the preference of the End User. ​ 

​​In the application content, in addition to the payment receiving functions, transaction transactions related to the ​Merchant​​     ​  will also be accessed. Access to transaction transactions will take place depending on the user's authority. ​ 

​​Through the Mobile POS application, the Merchant will be able to define the user for the ​Mobile Device​​     ​  application to its employees and will be able to give the users whatever they want from the authorizations to receive payments and access transactions. ​ 

​​Merchant has an obligation to immediately notify ​PAPEL​​​ in case of loss/stolen of the Mobile Device​​​     ​ on which the application is installed. ​​​ 

​​If PAPEL determines that the ​Mobile Device​​     ​ application is used in violation of ​this Agreement​​     ​, ​the purpose of the Agreement​​     ​ or the law by the Merchant itself and/or its employees, PAPEL has the right to close it without any warning or notice, and has the right to terminate this Agreement with just cause. ​​​ 

​​The transaction and Chargeback flows applied in physical POSs will also be valid for transactions made from the Mobile Device application.​ 

​​CARD STORAGE SERVICE​ 

​​"Card Storage Service" means the services for recording the Payment Card information of End Users who are a party to the transaction in online payment transactions made in e-commerce and/or other electronic media, and for using their registered Cards for payments to be made by the Merchant.​ 

​​With this Agreement, PAPEL also provides the card storage infrastructure that the Merchant can use for its own activities by storing the payment card information of the End Users in e-commerce and/or other electronic media within PAPEL with the approval of the End Users.​ 

​​Merchant​​ will not keep the payment card data of the End Users in any way, take all kinds of technical and administrative measures for the security of this data, cannot use this​ Card Storage Service​​     ​  without the approval of the End Users, in case of approval, payment card information ​only​​​ limited to the approved transaction and PAPEL can only share the card information stored within this transaction only if the user consents to the requested transaction.​ 

​​In order to provide the Card Storage Service, a contract must be concluded between PAPEL and the End User, and the Card Storage Service infrastructure will not be used by the Merchant in case the End User terminates the contract with PAPEL or if this contract is terminated for any other reason. In this context, PAPEL does not make any commitments to the Merchant.​ 

​​Within the scope of the Card Storage Service, PAPEL's responsibility consists only of providing the technical infrastructure and cannot be held responsible for any reason and/or name regarding the transactions made with the ​Card​​     ​ being stored. Transactions made with card storage will be subject to the rules applied to transactions between PAPEL and Merchant and liability will be regulated according to these rules.​ 

B- ELECTRONIC MONEY TRANSFER SERVICE ​     ​TO THE PAYMENT ACCOUNT ​OF THE​ CONTRACTED MERCHANT 

​​Merchant accepts that Electronic Money can be used for payments to be made by End Users. PAPEL​​may issue Electronic Money in an amount corresponding to the ​Fund​​     ​ received from End Users upon request by End Users. If the payment is made in the form of Electronic Money, PAPEL ​transfers​​     ​  the remaining Electronic Money to the Payment Account of the Merchant after making the commission rates and ​other necessary ​​     ​ deductions determined in the Agreement.​ 

​​The Merchant accepts that after the payment, the Electronic Money transferred to the Electronic Money account with ​PAPEL should make a request​​     ​  in order to partially or completely convert it to the Fund. Merchant​​ submits this request only through the Payment​     ​ Management Panel and/or in other ways​​ agreed in this Agreement.​ 

​​When the Merchant submits the request for ​Funding​​     ​  to PAPEL through valid methods, PAPEL will transfer the requested amount of Electronic Money into ​Funds​​     ​ and transfer it to the defined Bank account of the Merchant, unless PAPEL has any other obligation in ​accordance​​     ​  with the provisions of the law and relevant legislation.​ 

​​PAPEL conducts the necessary investigations in case the transaction regarding the ​Electronic Money​​     ​ requested for conversion to the Fund and refund is suspected to be an incorrect-unauthorized-false transaction or if the customer and/or banks object to the relevant transaction. If no negative results are encountered as a result of the research, the requested amount of Electronic Money is converted into the ​​​Fund and transferred​​     ​ to the bank account of the Merchant defined under PAPEL.​ 

​​It is illegal to charge interest for the amounts transferred to PAPEL, to ​Electronic Money​​     ​, ​ to the Fund issued in return for Electronic Money​​     ​, to give credits related to these amounts, and to ​gain​​     ​  benefits and results from these amounts. Merchant accepts, declares and undertakes that it will not make such demands.​ 

​​The Merchant must use the Electronic Money or convert it into a Fund within 2 (two) years following the transfer of the Electronic Money to the Payment Account as specified in ​​​​​​​​​​Article 1 above. Electronic Money that is not used within this period and is not converted into ​the Fund​​​​​     ​ will be transferred​​​ to the Merchant after being converted to the ​Fund​​     ​  without any demand or notification obligation at the end of the period.​ 

​​RIGHTS AND LIABILITIES OF THE PARTIES​ 

​​RIGHTS AND OBLIGATIONS OF CONTRACTED MERCHANT​ 

​​​With the signing of this Agreement, Merchant accepts, declares and undertakes that it has been informed about the Application Conditions, that the information entered in the Application Form and uploaded  to the system is correct, that it has read and understood the contents of the specified annexes and will act in accordance with them.​​ 

​​​Merchant accepts, declares and undertakes to work with PAPEL in accordance with the conditions set forth in this Agreement and its annexes and that PAPEL can change these conditions in accordance with the 14.5 provision of the Agreement.​​ 

​​Merchant will use PAPEL ​Services​​     ​  only for the purpose specified in this Agreement, under the scope and ​conditions specified​​     ​  in the Agreement and its annexes, and will pay PAPEL the relevant service fee specified in article 5​​​​ for each transaction.​ 

​​Merchant agrees and undertakes to indemnify all kinds of damages and expenses of ​PAPEL, ​​     ​PAPEL representatives and employees, arising from the violation of the legislation and/or the rights of a third party with respect to this Agreement and its annexes or the use of ​PAPEL​ Services, immediately and in full. The indemnification liability referred to in this article means all losses, financial liabilities, positive and negative damages, costs, demands and expenses (including legal fees, attorneys' fees and expenses, costs of investigation, litigation, settlement, judgment, interest, penalties and litigation on a full indemnity basis) arising from PAPEL itself and third parties.​​​ 

​​​PAPEL will provide the necessary maintenance, hardware and software for the systems related to PAPEL Services to function properly. During the specified works, PAPEL reserves the right to temporarily stop or limit the operation of the systems, provided that it notifies the Merchant in advance.​​ 

​​Merchant accepts, declares and undertakes that it will not engage in any cash sale transactions that will provide benefits to ​itself​​     ​ or to third parties by transacting in a way that does not comply with their real will in the Payment Service ​received​​     ​ via POS in order to earn profits by using a payment card belonging to itself or a third party​     ​. In case such a transaction is detected by PAPEL or official authorities, PAPEL(without prejudice to legal and contractual rights, especially to demand compensation for damages) has the right to unilaterally stop the use of POS and/or other ​PAPEL Services​​​ and also to terminate the Agreement immediately if it wishes.​ 

​​Unless the Merchant has obtained prior written permission from PAPEL, POS's will be used exclusively by itself, the bank account number to be declared from the system for the transfer of payments at any stage of the Agreement will/will be exclusively owned by the Merchant, upon sending the money to the IBAN number it has notified. He/she accepts, declares and undertakes that PAPEL will get rid of its debt. ​In case of a change in the bank account information notified to PAPEL by the Merchant and registered in the system, the said change must be notified to PAPEL in writing within 1 (one) day. Otherwise, PAPEL shall not be liable for the losses arising from the transfer of the balance of the Merchant's card account to the changed bank account. ​​In addition,​​     ​ violation of this provision will constitute a justified reason for the unilateral termination of the Agreement by PAPEL and the ​​suspension of the use of POS, and the Merchant is obliged to indemnify PAPEL in cash and in full, upon its first request, for all kinds of damages suffered or to be incurred by PAPEL.​ 

​​​     ​In any case considered to be r​isky by PAPEL, Merchant ​​     ​accepts ​and​ declares that PAPEL may suspend the payment until the risk is eliminated.​ In such a case, PAPEL will not need to obtain any prior permission from the Merchant.​​ 

​​Merchant, on its own responsibility of any kind that may arise otherwise, must use the password/password/username etc. given to it by PAPEL is obliged to keep the tools confidential, not to share them with unauthorized persons in any way and to prevent them from being used for malicious purposes. Merchant knows and accepts that PAPEL cannot be held responsible for any damage it may incur due to other circumstances such as the loss or disclosure of the password.​ 

​​Merchant will take the necessary measures to ensure the confidentiality and security of Sensitive Payment Data, and will not keep, store, process or record Sensitive Payment Data on its own. Merchant is obliged to notify the relevant situation directly and immediately to the PAPEL call center by telephone at 0 (850) 241 24 24 and/or by e-mail to the ​​​address​ at papel https://papel.com.tr/, in case of any Security Incident is noticed. In the event that ​Credit Card​​     ​ information and other payment information belonging to End Users are provided by the Merchant in any way during the sale of goods and/or services, this information will be deleted immediately after the completion of the sale, and any ​responsibility regarding​ the security of this information belongs to the Merchant.​ 

​​Merchant accepts, declares and undertakes that it will comply with the internal control procedures carried out by ​PAPEL​ in order to fulfill its obligations under the Law No. 5549 and to prevent fraud, that it will cooperate ​with PAPEL​ in every way in this regard, and that it will immediately provide​     ​  the information and documents requested by PAPEL. The Merchant where POSs are used is responsible for fulfilling the obligations imposed by the Law No. 5549.​ 

​​Merchant is obliged to provide PAPEL with the following documents regarding the refund of the Payment Transaction, which is the ​subject of​ ​     ​ the​ End User's complaint and the request of the Banks​​​, within 1 (one) business day at the latest from the date of request:​ 

Invoice submitted to the End User, including the taxes, including the End User's identity information,  

Log records showing the date and time of the End User's access to the ​Website​​     ​  and the End User's IP number, 

A copy of the confirmation that the products have been sent or that the requested services have been provided, 

Identity information of the End User who has ordered the products or services that are the subject of the complaint. 

In case the documents are not provided in due time or even if provided, if the End User complaint is understood to be justified, PAPEL​​ shall have the right to unilaterally deduct the relevant amounts from the Merchant's account, including ​commission, possible fees and sanctions​​     ​ or other payments. 

​​Merchant will not engage in the Forbidden Activities specified in Annex-2 and will only offer legal products ​and​​     ​​  services on the Website.​​     ​ In the event that PAPEL​​ determines that products or services containing Prohibited Activities​​ are offered, ​     ​ ​PAPEL​ shall have the authority to refuse payments and/or completely stop using the system, without prejudice to its contractual and legal rights.​ 

​​The Merchant will operate in accordance with the rules of the transaction partner Banks and Payment Card institutions,​​​ the laws of the Republic​​​​​ of Turkey and the applicable legislation rules, and will/will comply with these rules; It accepts, declares and undertakes that the provisions for the payments made by the End Users are/will be realized within the scope of these rules and procedures.​ 

Merchant is personally responsible for the penalties to be accrued to PAPEL and/or the damages to be incurred due to the sanctions to be applied in relation to the activities contrary to the rules of the legal authorities or card transaction institutions. 

​​Merchant accepts, declares and undertakes that it will be subject to the evaluation process by the risk management teams of the Banks it works with, in line with fraud and risk policy.​ 

​​The Merchant declares and accepts that it knows that PAPEL uses the products and systems offered and supplied by the transaction partner Banks for the operation of the system subject to the Agreement.​ 

​​​     ​In the event that the Merchant's field of activity changes, PAPEL Services ​​​​may be terminated or suspended ​​​​immediately without any notice if it is determined that the new field of activity does not comply with the service standards of PAPEL​​, its transaction partner Banks​​​​, VISA, MasterCard and other payment card​ institutions​​​.​ 

​​Merchant agrees and undertakes that it will include the logos (footer) of payment card institutions (Visa, Mastercard, etc.) on the payment page, and that it will not make any changes in this area without the written consent of PAPEL.​ 

​​Merchant accepts, declares and undertakes that ​the Agreement​​     ​  will be terminated automatically and immediately if the international payment card institutions (Amex, Visa, Mastercard, BKM)  cancel the registration of PAPEL, and ​in this case, ​​     ​ ​it will not​ make any compensation or other claims against PAPEL under any name.​ 

​​Merchant is obliged to make necessary notifications to End Users within the scope of the legislation, and accepts that it is responsible to PAPEL for penalties, damages, losses and other claims that may arise otherwise.​ 

​​Merchant accepts, declares and undertakes to transmit the End User IP accurately and completely to PAPEL in every transaction. In addition, Merchant undertakes to use static IP for its own transactions. ​Merchant​​​​​ is obliged to deliver the documents​​ proving that it owns the line of the IP number(s) that it declares in the Application Form, following the signing of the Contract, and if its static IP changes for any reason, it is obliged to notify PAPEL immediately and in writing​.​​ 

​​​Merchant, Article 10.5. without prejudice to its provision, it is obliged to notify PAPEL immediately (at the latest the next business day) and in writing of any changes that may affect the information and documents submitted to PAPEL within the scope of the Agreement, and​     ​​​     ​ accept that PAPEL will not be liable for any damages that may arise due to non-notification or late notification, declares and undertakes.​ 

​​If the services or products sold by the Merchant are the services or products for which the BRSA imposes an installment limit, the Merchant is obliged to declare this situation to PAPEL. If the Merchant does not fulfill this obligation and PAPEL therefore fails to provide the installment facility provided by the banks for a product for which the installment criteria have been determined, then it will pay all fines, indemnities and other amounts incurred by PAPEL to PAPEL in cash and in full upon its first request, accepting and declaring that and commits. It is published under the "Legislation, Debit Cards and Credit Cards Law and Regulations Related to the Law" on the ​website of the BRSA at www.bddk.org.tr, and the Merchant ​is obliged to follow the rules up-to-date.​ 

​​Merchant shall declare that there is and/or will not be any transaction that falls within the scope of the definition of fictitious transaction within the scope of professional arrangement decision and all relevant legislation, and that it will use the POS(s) allocated to the Merchant and/or whose actual use has been left to third parties, in accordance with the Agreement and the procedure. that PAPEL is and will be personally and exclusively responsible for all unlawful uses that will ​​not be permitted, that PAPEL is also authorized to terminate the Agreement unilaterally immediately with just cause, in the event that the fictitious committee decides on non-purpose use on behalf of the Merchant, in parallel with the professional arrangement decision, It accepts, declares and undertakes that it can share all kinds of information and documents of the Merchant with the legal authorities within the framework of the legislation.​ 

​​Merchant accepts, declares and undertakes that it is obliged to provide information on recurring payments and to receive instructions from the End User regarding recurring regular payments, in case of the End User's request, and that it will be responsible for all damages that may occur in case of contradiction. In case the Merchant offers Card Storage Service​​ and a service related to recurring payments related to this product, from the Card Holder;​​ It will provide/fulfill the card supply agreement approval, ​Card Holder​​     ​  instruction for recurring payments, Card Holder approval within the scope of KVKK, enlightenment of the ​Card Holder​​     ​  within the scope of KVKK from the ​Card Holder​​     ​  in accordance with the requirements expected by the legislation and the relevant contract upon the first request of PAPEL, declares, accepts and undertakes to forward the information and documents to PAPEL immediately.​ 

​​Merchant shall indemnify all kinds of losses incurred by PAPEL in cash due to the fact that the Merchant does not fully and completely fulfill its obligations under this Agreement​     ​ and/or the actions of the business partners providing services on PAPEL payment systems under any name on its behalf and in addition to the ​compensation​ hereby, It is obliged to pay the penal clause determined in ​Article 11​ of the Contract in full and in cash upon the first written request.​ 

​​Regarding Merchant ​PAPEL Services​, ​​PAPEL​     ​ will cooperate with PAPEL to ​​     ​fulfill​ all obligations imposed​     ​ on it by banks and official institutions, In this context, it accepts, declares and undertakes that ​it will fulfill PAPEL's​​ demands​ immediately​     ​  and will cover all damages to PAPEL due to the ​violation.​ In particular, the agreement and additional protocols signed by PAPEL with the Banks in terms of determining the liabilities with the Banks bind the Parties.​ 

​​Merchant, to obtain all kinds of documents that are expected/should be arranged by PAPEL legally regarding the transactions made, to be obtained from the End User and to be given to the End User, and to keep them for ​10 (ten) years​ and PAPEL​, upon ​its first request, are obliged to immediately provide the relevant documents or additional ​new documents and​​     ​ submit them to PAPEL immediately and within 3 (three) business days at the latest.​ 

​​Merchant is obliged to protect ​the Website​​     ​ and its servers from virus attacks or third party attacks with the protections provided by third parties with appropriate experience and expertise.​ 

​​The Merchant is obliged to present the information required to be submitted pursuant to the legislation in force, especially the information below, on the ​Internet Site:​​     ​ ​ 

Product return/Cancellation Policy, 

Preliminary Information Form and Distance Sales Contract, 

SSL Certificate, 

Contact Information (Full address resident in Turkey, fixed telephone etc.) 

Delivery terms, 

KVKK, Privacy Security Policy, 

Creating a Basket for a Product/Service 

PCI-DSS for card storage 

Other mandatory information/documents due to the nature of the business 

​​Merchant will fulfill all its obligations regarding taxation and certification under other legislation for each product and/or service sold through the ​Website.​​​ In the event that the goods or services to be offered within the scope of the Merchant ​PAPEL Services​​​ or any campaign to be organized in relation to this product or service require a special permission, it will be personally responsible for providing this permission. Records of the aforesaid permissions will be ​​kept for a minimum of 10 (ten) years to be submitted to PAPEL upon request.​ 

​​Merchant ​     ​​​     ​​​     ​ shall not make any written or social media statements against PAPEL directly or indirectly and that PAPEL will not take any action that may harm its commercial reputation, in accordance with the Turkish Commercial Code No. ​6102 (“TCC”), for the duration of this Agreement and for 2 (two) years from the termination of the Agreement in any way, not to enter into any agreements, partnerships or other commercial relations with its competitors doing the same or similar business as  ​​PAPEL, with the aim of directly or indirectly preventing, disrupting or restricting PAPEL's​ field of activity, It accepts, declares and undertakes that it will act as a prudent merchant during the period of this Agreement in force. In the event that the Merchant violates this article, PAPEL will compensate directly or indirectly the losses incurred by PAPEL per each customer created with an unfair impression in cash, upon the first request, and that PAPEL's rights regarding surplus and legal and contractual rights are also reserved in Article 11 of the Agreement, It accepts, declares and undertakes to pay the specified penal clause.​ 

​​Merchant is obliged to ensure data security within its domain and to ensure that ​data entries cannot be changed​ in an unauthorized manner. In the event that the Merchant has a justified opinion that the person using any Payment Instrument is not the person authorized to use that Payment Instrument or has the information to perform the transaction, the ​     ​Merchant shall, under its own responsibility, examine the relevant authorizations and carry out the transaction. If he/she believes that it is fraudulent, he is obliged not to accept the payment and will immediately notify PAPEL of this matter.​ 

​​Merchant will issue the invoice/receipt for the goods and/or services sold through ​PAPEL Services​​     ​  without waiting for collection during shopping. Merchant will issue the invoice/receipt on behalf of the End User and deliver it to the End User. All tax liabilities regarding the goods and/or services in question are the responsibility of the Merchant, and the Merchant will be the sole addressee and responsible for any request that may come from third parties, institutions and/or organizations. The risk regarding the collection of the goods​ ​​     ​​     ​​and/or service ​  fee belongs to the Merchant. PAPEL will not be responsible for the realization of the collection.​ 

​​In case the Merchant ​​ ​     ​does not fulfill its contractual and/or legal obligations​​ at all or duly or on time​     ​, all​ responsibility​​     ​ ​belongs​ to itself, in this case, in case of any objections and complaints that may come from both End Users and third parties, institutions and organizations, the ​     ​End User, Banks and other accepts that it will not direct any third person, institution or organization to PAPEL, and that if PAPEL​     ​  is shown to be responsible directly or jointly in any dispute pursuant to the relevant legislation, it will immediately pay PAPEL any amount that ​PAPEL​​     ​  has to pay, in cash and in full, with interest and taxes, and commits.​ 

​​RIGHTS AND OBLIGATIONS OF PAPEL​ 

​​PAPEL, within the scope of its authority and responsibility arising from the legislation to which it is subject, is responsible to the Merchant for the correct execution of the ​Payment Transaction​​​ within the framework of the relevant legislation. However, if the End User Payment Service Provider cannot prove that it has transferred the payment amount to PAPEL in accordance with Article 52(1) of the Regulation, PAPEL shall not be liable for the non-execution of the ​Payment Transaction.​​​​ 

In case of an Unlawful Transaction notification to PAPEL by the End User Payment Service Provider, if the Merchant cannot prove that the goods/services ​     ​ ​have​​​ been supplied according to the PAPEL Chargeback rules, this fee is ​sent​ to the End User Payment Service ​Provider​​     ​ before​     ​ the transfer is made, unless it has been transferred to the Merchant, If the fee has been transferred, the Merchant sends the fee back to PAPEL to be transferred to the End User Payment Service Provider within 10 (ten) days, or if PAPEL deems it necessary, it deducts the relevant amount from the payments to be made to the Merchant.​​ In cases where PAPEL is responsible, the part of the ​Payment Transaction​​     ​ that has not been realized or realized incorrectly will be returned to the Merchant without delay and the ​Payment Account​​     ​ will be restored if the amount has been deducted from the Payment Account. 

​​PAPEL will accept the payments made by the End Users for the products and services provided by the Merchant and will make these payments within the scope of the principles stipulated in this ​     ​Agreement, starting from the first business day following the transaction and for more than 540 days, in the absence of a Security Incident, will be transferred to the bank account of the Payment Service User, after deduction of fees and commissions determined in bulk and within the scope of the Agreement, at the end of the maturity period to be determined in accordance with the risk policies. In the event of a Security Incident, PAPEL has the authority to hold payments.​ 

​​Provided that the provisions of the legislation applied to the Merchant are reserved, PAPEL will be able to access the account statements regarding the transactions made by the End Users with the PAPEL POS via the POS application, at the end of each month and/or at the periods determined by PAPEL, via the ​Payment​ Management Panel. The receipt will be sent to the​​​ End User​ once a month. In addition, the details of the transaction made through the ​Payment​ Management Panel will be visible to the Merchant. Merchant is obliged to reconcile the transactions and, in case of a negative situation, ​notify​ PAPEL in writing within 10 (ten) days at the latest. Merchant accepts that PAPEL has no responsibility for disputes that may arise due to non-delivery or late notification.​ 

​​In order to facilitate the processes related to payment transactions and to prevent fraud and malicious use activities in the field of payments, the merchant that offers goods and services with a payment method within the scope of ​PAPEL Services​​     ​ , is given a merchant ​     ​ code specific to the ​Merchant by Bankaları Kart Merkezi A.Ş.​​     ​  Papel ​     ​has to use the workplace code created in accordance with this article in the ​PAPEL​​     ​ Services it will offer.​ 

​​In case ​PAPEL​​     ​, terminates PAPEL Services for commercial and/or non-technical reasons​, PAPEL notifies​ this situation ​​and the reason for termination of the ​Agreement​​​ to the ​Merchant​​     ​  registration system.​ 

​​​PAPEL ​​     ​, ​checks regularly,​​     ​  at least once a year, whether there is a negative record of the workplaces it serves in the Merchant registration system, and if there is a record, it takes the necessary measures according to the content of the record.​ 

​​In the event that ​PAPEL​​     ​ ​ uses the infrastructure provided by another End User Payment Service Provider​​     ​  for ​PAPEL​ ​     ​​ Services​ to ​​enable it to sell goods and services with a payment method within the scope of Merchant payment service​     ​ , the workplace code defined in the Add Label ​Merchant​ registration system, tax ID number, TR identity ​​​​number and foreigner identification number, whichever is appropriate, must notify the ​End User Payment Service​​     ​  Provider, whose infrastructure is used, within ten working days at the latest.​ 

​​If the infrastructure of ​PAPEL​​     ​  is used by a different End User Payment Service Provider​​, PAPEL cannot request any other information of the ​End User Payment​​     ​ Service Provider's workplace from the ​End User​​     ​ ​Payment Service Provider​​     ​ using the infrastructure, except for the matters specified in this paragraph and the ​transactions performed using its​ infrastructure. ​ 

If the infrastructure of ​PAPEL​​     ​  is used by a different End User Payment Service Provider​,​ Papel has the right to ​request the End User Payment​​     ​  Service Provider not to provide services to the workplace as a ​result​​     ​ i of the assessments it will make within the framework of risk policies. ​PAPEL ​​     ​,cannot prevent the payment service to be provided to the workplace by the ​End User Payment Service Provider​​​ using the infrastructure for arbitrary reasons. Papel's rights and powers within the scope of the relevant legislation provisions are reserved. For this reason, Papel can only use the information notified to it in the investigation and detection studies to be carried out within the framework of risk assessment and relevant regulations, and cannot use it for any reason other than for purposes such as marketing and customer acquisition. 

​​​PAPEL​​     ​​​     ​​​     ​ ​ is obliged to record​​     ​ ​     ​ the transactions within the scope of​ PAPEL​​     ​​ ​​Services​ offered to the ​     ​Merchant in such a way ​that it can be followed by the Merchant.​​     ​​ 

​​PAPEL​     ​ is obliged to provide the Merchant with the information necessary for the performance of the obligations of the ​Merchant​​     ​ to fulfill its obligations under this Agreement and to prepare the necessary documentation ​for the training of the Merchant personnel, if requested by the Merchant​​     ​.​ 

​​PAPEL establishes follow-up mechanisms for all transactions carried out by customers and workplaces​, in order to detect and prevent fraudulent or​​     ​  fraudulent transactions and transactions that may be considered as financial crimes. In this context, ​PAPEL​ evaluates suspicious or high-risk transactions in detail. ​PAPEL​​     ​ is obliged to carry out an effective follow-up regarding the ​transactions​​     ​ realized​, the Merchant ​​party​​     ​ to the transaction​ and the PAPEL Services​​     ​. In this context, ​PAPEL​​     ​ ​ is responsible for carrying out risk assessment studies for the Merchant​​     ​ , being informed about the compatibility of the service provided by the ​Merchant​​     ​  with the service offered in reality and not subject to social engineering activities, reviewing the risk assessment in case of heavy customer complaints regarding the services and taking the necessary measures.​ 

​​PAPEL will provide effective access to the PAPEL call center in order to solve the problems experienced about security and data privacy and will make maximum efforts to eliminate the problems and disruptions in this regard.​ 

​​Merchant, ​E-mail: https://papel.com.tr/ and Telephone: 0 (850) 241 24 24 support line (“​​Support Line​​”)​​     ​  between 09.00 – 18.00 excluding Saturday, Sunday and public - national holidays can be reached via the following e-mail and telephone in case of emergency: ​     ​​​​ 

​​​PAPEL has the right to inspect, at any time, whether Merchant activities are carried out in accordance with this Agreement  or to request the inspection​​  and​​ testing of these Services from a third party and/or institution. ​​Papel's, audit right referred to in this article will be limited only to the works and transactions carried out under the Agreement. Merchant accepts, declares and undertakes to enable PAPEL or a third person and/or institution authorized by PAPEL to access all kinds of information and documents related to the Contract during the audit.​​ 

​​Merchant shall notify PAPEL of the malfunction in the event that the Payment Service, the Merchant and/or the personnel working at the Merchant do not work, stop working or cannot be identified without any fault. PAPEL is responsible for the elimination of the malfunction that occurs without any fault of the Merchant and/or its personnel.​ 

​​All​     ​ notifications that must be made within the framework of the obligations mentioned in this Agreement and in terms of Law No. 6493 and related legislation will be made by PAPEL to the e-mail address of the person/persons notified by the authorized persons of the Merchant. Notifications will be made as often as required under the legislation or when PAPEL deems it necessary.​ 

​​Changes in the working conditions and/or internal organization and working structure of PAPEL's cooperation with both banks and other institutions and organizations, and the Merchant's violation of this Agreement and the provisions of the applicable legislation or regulatory administrative procedures, failure to take the necessary measures to establish transaction security, and PAPEL, without limitation, has the right to suspend the ​Payment Services​​     ​ offered to the Merchant within the scope of the Agreement, as well as to suspend the services and to close POS, when it deems necessary, without prejudice to its legal and contractual rights.​ 

​​PAPEL  ​     ​is not responsible for the failure of the Payment Service and the Payment Management Panel due to problems not caused by its own system. PAPEL will make every effort to keep the system operating normally. PAPEL is not responsible for the failure of the Payment Service and the Payment Management Panel  ​     ​due to problems not caused by its own system.​ 

​​When problems and/or system failures occur due to service infrastructure, the failures are tried to be resolved in accordance with the legislation. PAPEL has no liability in case the amounts transferred to PAPEL by the End User and/or End User Payment Service Provider cannot be transferred to the Merchant while the troubleshooting works continue within the legal periods.​ 

​​Merchant will forward their complaints and requests regarding the Payment Service directly to PAPEL's Support Line via telephone and​/or​ e-mail. Merchant accepts that the confidentiality of complaints and requests is essential.​ 

​​PAPEL shall not be liable for the delay in transferring the relevant payment order amount to the End User's account due to the ​delays​​     ​ ​ or failure to complete the transactions due to incorrect or ​​     ​incomplete information entered by the Merchant after the payment order is placed. PAPEL shall not be liable for delays, failure to perform the transaction, or any errors that occur in the transaction other than its own fault, as a result of situations that cannot be foreseen or prevented by PAPEL, or for damages resulting from these.​ 

​​FEES AND COMMISSIONS​ 

​​Fees and/or commission fees and rates for ​PAPEL Services​​     ​  to be provided to the Merchant by PAPEL within the scope of the Agreement, as well as the payment terms for the said fees, are determined in the Annex of FEES, COMMISSIONS and TERMS and PENALTY CONDITIONS in ​ANNEX-3​​     ​  ​of the Agreement.​ These prices and rates will be announced on the Payment ​     ​ Management Panel.​ 

​​Updates to be made in fees and/or commission rates and rates related to ​PAPEL​​     ​ Services, commission rates by the Central Bank or ​Credit Card​​     ​ exchange commission rates by banks, all kinds of fees reflected to PAPEL by banks and/or regulatory institutions and organizations, and pricing made by banks. In cases where changes are required due to any increase, PAPEL shall notify the Merchant in a reasonable time in advance and collect these increases from the Merchant. Changes to be made by PAPEL for other reasons will be valid by mutual agreement of the Parties.​ 

​​SECURITY MEASURES​ 

​​The Merchant will be able to follow the transactions within the scope of the Contract ​PAPEL Service​​     ​  through​     ​ the Payment ​     ​Management Panel, which is an interface accessed by PAPEL. Access to this interface​     ​ will be done with a password to be allocated to the Merchant and this password is specific to the Merchant. Merchant is obliged to keep the password confidential, not to disclose it to unauthorized persons and to prevent the use of the password for purposes other than the allocation purpose. The Merchant will immediately notify PAPEL that the password is in the hands of unauthorized persons, and unless such a written notification is made, all transactions carried out using the password are deemed to be carried out by the Merchant itself and the Merchant will be personally responsible for these transactions. If the password and other identification information (authorized information, bank account, etc.) specified by the Merchant are incorrect, PAPEL cannot be held responsible for not performing the relevant ​Payment Transaction​​     ​  completely and correctly.​​ Merchant is also obliged to take the necessary measures regarding the use of ​PAPEL Services​​     ​  and to protect personal security information ​and​ to use ​PAPEL Services​​     ​  in accordance with the terms of use.​ 

​​According to Article 15 of Law No. 5549; A person who acts on his own behalf but on behalf of someone else in transactions that require identification, to be made before or through the obliged parties, is punished with imprisonment from six months to one year or a judicial fine up to five thousand days, unless he informs the obliged parties in writing on whose account he is acting before carrying out these transactions. In case of acting on behalf of someone else, natural and legal persons who have made transactions that require identification within the framework of the Law No. 5549, relevant regulations and communiqués before PAPEL, must declare in writing to PAPEL before making the transaction on whose account they are acting. In cases where it is not declared that the transaction is made on behalf of someone else, it is considered that the real or legal person making the transaction is doing the transaction on his/her own behalf and account.​ 

​​Merchant is obliged to take appropriate security measures for the protection of privacy and fundamental rights and freedoms of individuals within the scope of KVKK regarding the processing of any personal data it transfers to PAPEL.​ 

​​Merchant is obliged to take all kinds of security measures in all matters and cannot hold PAPEL responsible for any damages that may occur in this regard and cannot make any demands.​ 

​​Merchant also, while using the Mobile POS Application; ​obliged to​​     ​ comply with the following: ​ 

​​The Merchant will not ask the Card Holder to verbally explain the card password. While using the application, it will present the Mobile Device with only the password entry screen open and the password entry screen facing the Card Holder. ​ 

​​​The Merchant​ will not receive a password or card number from the Card Holder in any medium and in any way other than the application. It will not open another application ​and​​     ​  will not interfere with the POS application during application operations and password entry. ​ 

​​​Merchant​​     ​ must use the application on a ​Mobile Device​​     ​  that does not have technical or physical defects. It will not use the Application on the Mobile Device with any malfunction. ​ 

​​​Merchant ​​     ​​​will not open any iOS or Android notification (push notification) while the password entry screen is active, and will not drag any iOS or Android screen from top to bottom. ​ 

​​​Merchant​ will not use the iOS or Android navigation button​​​ as long as the Password entry screen is active on the Mobile Device.​​     ​ ​ 

​​Merchant will not activate any screen capture tool with any other application ​during the process​​     ​. If such a situation is detected, the transaction will be terminated immediately. Merchant is responsible for the security of the Mobile Device. ​ 

​​Since the applications to be installed on the iOS and Android platform may harm the ​Mobile Device and may pose a security risk​​     ​, Merchant will monitor the current malicious / ​fraudulent software, install an antivirus​​     ​ application on the device, update the application in order to detect whether these software are available in the installed applications or the IOS / Android operating system and take other necessary measures expected of him. ​In this context,​​     ​​​ Merchant will take the necessary measures against malicious, fraudulent persons who may try to gain unauthorized access to the Mobile Device​​​​ on which the application is installed, and will inform ​Mobile Device​​     ​  users on this matter. ​ 

​​​The Merchant​ must immediately close the application for use, or have the Application closed by immediately notifying PAPEL, in cases where the devices on which the Application is installed are removed from the possession of the Merchant, stolen, lost, or have a physical malfunction. ​ 

​​Merchant must clearly know that the Application is being monitored and any attacks, malicious/fraudulent activity, sabotage etc. are recorded in the backend. These records are used to detect security attacks and evaluate related security measures. ​ 

​​The application must be run over the network that the Merchant accepts as a "safe network". The internet connection for the application should not be made from public links. It may cause material damage to the Merchant and related third parties due to data loss.​ 

​​In case of not fulfilling the obligations specified in this article ​and​​     ​ belonging to the Merchant and not taking the necessary precautions, all kinds of ​damages​​     ​  and responsibilities that may occur shall belong to the Merchant. If the Merchant violates the specified matters and/or issues a ​Sales​​     ​  Document in violation, the Merchant has the authority not to pay the sales amount of PAPEL(without prejudice to its contractual and legal rights), to withdraw this amount ex officio from the Merchant account if it has been paid, and to demand it together with interest and all kinds of accessories if the account is not suitable accepts, declares and undertakes.​ 

​​IRREGULAR TRANSACTIONS AND CHARGES​ 

​​Merchant accepts and undertakes to carry out its transactions in accordance with this Agreement, the instructions of PAPEL​​​​, the rules​ determined by Card Payment​​     ​ Systems and other regulatory and supervisory institutions, and the legislation that will come into force and/or amended later.​ 

​​Merchant is obliged to follow the rules and related changes of national or international Card Payment System institutions and to comply with these rules and amendments immediately and without the need for further notice as of the effective date.​ 

​​In case the transactions are carried out in violation of the aforementioned rules and/or legislation or if the Member ​Merchant's​ Visa-MasterCard and other Card System Organizations enter into the tracking programs of activities that harm the ​brand, the​​​ Merchant, He/she is the subject of all criminal or legal sanctions (including the penalty amounts to be accrued by the National and International Card Payment Systems), in case PAPEL is exposed to any damage and/or penalty for these reasons, these costs, together with the interest and all its accessories, are requested by PAPEL ​​at the first request, accepts, declares and undertakes that he/she will pay immediately and in full and/or that he is authorized to deduct the damage suffered by PAPEL from the accounts of the Merchant without any notice.​ 

​​Current rules of international/national card/credit Card Payment Systems, of which Banks are/will be direct/indirect members such as EMV, Mastercard, Visa, BKM (Bankalararası Kart Merkezi), can be found on the Interbank Card Center www.bkm.com.tr website under “Useful Information  Merchant Guide”. is published and the Merchant is obliged to follow the specified rules up-to-date.​ 

​​Merchant accepts, declares and undertakes that it will not hold PAPEL responsible for any damages arising from fraud, fraudulent transactions and any other Irregular Transaction by third parties and will not make any compensation or claim against PAPEL. As soon as the Merchant learns of any fraud, fraudulent or other Illegal Transaction in this way, it immediately notifies PAPEL and makes a criminal complaint to the relevant authorities. Merchant is responsible for all kinds of transactions and actions of the persons it employs, directly or indirectly, without any limitation, even if these persons have left their positions.​ 

​​If the Merchant is a legal entity, its partners, representatives and other officials and any direct or indirect transactions and actions to be taken by the employees at the Merchant, including any irregularities, unrealistic returns, forgery, breach of trust and fraud acts. It accepts, declares and undertakes that all responsibility belongs to ​itself​​     ​  in case of making similar Irregular Transactions including but not limited to these.​ 

​​Using all or any part of PAPEL's website and applications for the purpose of disrupting, modifying, reverse engineering; Making transactions that impose an unreasonable or disproportionately large load on the application and the systems to ​which the site is connected, or blocking, stopping, disrupting, disrupting​ or changing the said systems in any way, or attempting to use the site and application in any way and attempting to access them; The use of automatic programs, robots, spiders, data mining, data scanning software or systems on the site and application, and in this way, copying or using all or part of any content on the site ​​​​and application without permission is prohibited, and these acts constitute a violation of the law and this Agreement. . Merchant will not attempt to obtain information from PAPEL systems in an unauthorized manner, either in person or through unauthorized third parties, will not interfere with the systems operated by PAPEL, and will not cause unauthorized access to PAPEL data networks.​ 

​​In the event that the request for suspension of the shopping transaction made by the End User individually, whether or not 3D Secure is used or not, on the grounds that it is a "suspicious transaction", is submitted to PAPEL​​ after  ​     ​the intermediation process for the Payment Service, of which PAPEL ​has received its infrastructure​, the PAPEL payment period expires. It has the right to delay the payment of individual shopping fees and not to make any payments to the Merchant, since it will refund the transaction amount if the risk materializes. This provision will be applicable only in case the transaction partner Banks notifies PAPEL and the End User that the End User's transaction is a "suspicious transaction", in accordance with the Law No. 6493 and the relevant legislation.​ 

​​PAPEL ​     ​may return a payment made to the End User, with or without 3D secure (a payment with the status of "completed"), in cases where the Merchant-sourced product cannot be delivered or the transaction carries a ​Chargeback​​     ​  risk, to the ​End User​, based on the Merchant's written instruction. Merchant is responsible for all damages that may occur in case of non-delivery/non-delivery of a good/service purchased by the End-User, or incomplete, defective delivery/delivery, and the ​Merchant​ agrees and declares that it will immediately indemnify these damages. In the event that the End User takes legal action against PAPEL due to compensation for its loss, this application will be notified to the Merchant and the Merchant will defend in favor of PAPEL, without prejudice to the contractual and legal rights of PAPEL. In the event that the decision of the court or the arbitral tribunal is concluded against PAPEL, all the fees that PAPEL will be liable to pay will be recourse to the Merchant.​ 

​​In the case of Merchant, product return or ​Chargeback​​     ​, whether it is 3D secure or not, the deductions that can be applied to PAPE by the banks can be deducted from the next payment price by PAPEL, or if there is no such amount, it can be requested separately by PAPEL, and in this case, this amount can be paid within 3 (three) business days. ​​It declares, accepts and undertakes that it is obliged to pay PAPEL. In such cases, the Merchant cannot request PAPEL​     ​  to refund the service fee. In the event that any End User's payment is returned by PAPEL, the taxes related to the transaction will be collected by ​​PAPEL by way of ​deduction​​     ​ or payment from the receivables of the Merchant. If the transaction partner Banks do not find it appropriate to work with the Merchant for security reasons, the use of payment systems defined on behalf of the Merchant will be immediately blocked by PAPEL. In this case, PAPEL​​ may terminate the Agreement immediately and unilaterally for just cause, without prejudice to its contractual ​and legal rights​​     ​.​ 

​​Merchant is the responsible and addressee party for ​Chargeback​​     ​ transactions arising from the use of the allocated POS. Any damages incurred by PAPEL (without preserving its contractual and legal rights) as a result of these transactions will be collected from the Merchant. In the case of Refunds and Chargebacks, PAPEL's fees and commissions (including all taxes) determined in the Agreement will be paid by the Merchant.​ 

​​In case the transactions made from the POS are considered risky, PAPEL will be able to block the Merchant account as much as the risk amount during the review period to be made by PAPEL.​ 

​​Even though the Merchant does not pay attention to the issues specified in the contract articles and the transaction made from the Merchant is approved, it is understood that it is fake or a suspicious transaction is observed,Banks and/or institutions and organizations where PAPEL works or does not work for any reason without any notice or warning, blocking all accounts of ​PAPEL, application​​     ​  of the value date, taking the Fund into its own possession, stopping/demanding the payment to be made to the Merchant, etc. In case of occurrence of PAPEL​,​​     ​  will apply the same processes for the Merchant, and the Merchant has irrevocably accepted and committed all these issues within the scope of this Agreement in advance.​ 

​​If PAPEL is notified that the payment was ​​made without authorization or that an incorrect​     ​ or fraudulent transaction was made, the transaction is suspended. After the necessary examination and research, PAPEL, if the End User is right, returns ​the price paid​​     ​ to the End User. If the relevant price has been communicated to the End User before the specified notification, the Merchant will return the relevant price to PAPEL within 2 (two) business days.​​​     ​ PAPEL reserves the right to deduct such amounts from the payments to be made to the ​​Merchant. In cases where ​it is caused​​     ​ by a related unauthorized or erroneous transaction defect, the unrealized or ​incorrectly realized portion of the Payment​​     ​  Transaction will be returned to the Merchant and if the amount has been deducted from the Payment Account, the Payment Account will be restored.​ 

​​Merchant immediately submits the information and documents that will be the basis for its defense regarding the objected transaction to PAPEL within ​​24 (twenty-four) hours at the latest. If defense and support documents are not submitted within this period or if the objection is found to be justified,​​ 25-TL (twenty-five Turkish Liras) penal clause (excluding taxes) is paid to PAPEL by the Merchant. PAPEL may deduct this amount from the Merchant's account at PAPEL by notifying the Merchant.​ 

​​​​Pursuant to Article ​     ​​54(4)​ of the Regulation; In the event that the End User uses the Payment Tool by others due to the use of a lost or stolen Payment ​     ​Instrument by the End User, or due to the End User not keeping the personal security information duly, the End User or the owner of the Payment Instrument shall, in the event that the End User or the owner of the Payment Tool, is responsible for the portion up to the amount of ​     ​​2​50-TL of the damage incurred during the period. Merchant is responsible for the portion exceeding​ 250​​     ​ -TL (​two​ hundred and fifty Turkish Liras). In this context, all costs that PAPEL has to pay will be covered by the Merchant. PAPEL will be able to deduct these amounts from the payments to be made to the Merchant, provided that it is notified to the Merchant within a reasonable time. In case the End User uses the Payment Tool fraudulently or fails to fulfill its obligations regarding the ​Payment Tool​​     ​  willfully or grossly negligently, the Merchant will not be liable for the damages arising from the unauthorized Payment Transaction and the End User will be responsible for all the damage.​ 

​​In the event that the said unauthorized transaction originates from the Merchant, the Merchant​,​  End User and/or End User​​ Payment Service Provider shall indemnify the damages to be incurred by PAPEL and shall also pay all the fees (interest, penalty, including expenses and all kinds of accessories) is obliged to pay. In addition, the Merchant is obliged to transfer the unauthorized amounts collected from the End User to PAPEL to be ​​returned to the End User. In the event that it is not possible to detect a defect within the scope ​     ​of the transaction, all fees over ​     ​​2​50-TL that PAPEL will have to pay to the End User and/or End User Payment Service Provider and/or any other third party shall be collected from the Merchant or deemed ​     ​(including interest, penalty, expense and all kinds of accessories)  necessary, shall be deducted from the payments to be made to the Merchant.​ 

​​In the event that PAPEL​     ​ suspects that a faulty-unauthorized-false transaction has been made as a result of the investigations and determinations made ​by​​     ​  itself at any time,​     ​  the relevant amounts are suspended and held without being transferred to the Merchant by the ​End User​ until the investigations​​​     ​ at PAPEL are concluded ​​​​within a reasonable time. In this case, the Merchant immediately submits the information and documents requested by PAPEL to PAPEL within 24 (twenty-four) hours at the latest. PAPEL​​  will inform the Merchant about the reason and duration of the suspension, unless there is a situation preventing the disclosure of information or objective reasons threatening the security in accordance with the relevant legislation. When the reason for the suspension of access disappears, ​     ​PAPEL will provide ​​the Merchant with partial or complete access to the Payment Service and PAPEL Services.​ 

​​The Merchant is responsible for any refund transactions that may occur due to the Merchant's breach of its obligation to submit information and documents. In such a case, the Merchant transfers the relevant fees to PAPEL in cash and in full, with all its accessories, in line with PAPEL's request. Merchant accepts that it reserves the right to set off the amounts that PAPEL will transfer to the Merchant without the need for any notification regarding the said fees. Merchant​​, when requested by PAPEL, is obliged to inform PAPEL accurately and completely the URL address information and IP numbers of the transaction subject to the request, provided that it is not contrary to any legislation or confidentiality agreements to which the Merchant is a party. Merchant is directly responsible for the material and penal sanctions that PAPEL may be exposed to due to incomplete and incorrect reporting of this information.​ 

​​In cases where the Merchant does not supply products or services to the End User, the said amount is returned to the End User​     ​, unless it is transferred to the Merchant. If the said amount has been transferred to the Merchant, the Merchant sends these amounts back to PAPEL ​in​​     ​ order to return it to the End User. If these amounts are not sent to PAPEL within the periods specified in the legislation or if PAPEL deems it necessary, PAPEL sends the relevant amounts to the End User to deduct from the payments to be made to the Merchant.​ 

​​If the said unauthorized and/or erroneous transaction is caused by a defect arising from PAPEL's system, the commission received for the part of the ​Payment Transaction​​     ​  that has not been realized or is realized incorrectly will be returned to the Merchant by PAPEL within 2 (two) working days. If the amounts in question are deducted from the Merchant's account, the Merchant's account is restored within 2 (two) business days.​ 

​​In the event that the number of Illegal Transactions exceeds 3% of the successful transactions of the Merchant on a weekly, monthly, or annual basis, PAPEL has the right to suspend and terminate the Payment Service and PAPEL Services it offers, by notifying the Merchant in a reasonable period of time.​ 

​​​​In the event that PAPEL is notified that a Chargeback has been made by the End User, PAPEL has the right to return the relevant transaction to the End User's ​Credit Card​​     ​  and deduct the refunded amount, as well as the gradual penal clauses in ANNEX-3​     ​ for each Chargeback transaction, from the balance of the Merchant.​ 

​​In case the rate of Chargeback transactions exceeds 1% of Successful Transactions, PAPEL shall be entitled to hold the balance of the Merchant for at least 6 (six) months upon termination of this Agreement, in case of the risk to arise.​ 

​​In order to determine that the person using the Merchant Payment Service ​​and the Payment Instrument is authorized to use it or has the information to make transactions, it will establish the necessary control mechanisms and take measures within the framework of industry standards. In case of detection or suspicion of unauthorized and/or fraudulent use of the Merchant Payment Service, it examines the End User and immediately notifies PAPEL in writing.​ 

​​In the event that the identifier specified by the End User is incorrect, ​     ​ PAPEL​​ ​ cannot​​     ​  be held responsible for the non-execution or incorrect execution of the Payment Transaction. Likewise, PAPEL is not responsible for delays and faulty transactions that may occur due to inaccurate ​​or incomplete information to be submitted by the End User and other End User-related problems.​ 

​​IMPARTIALITY​ 

​​Within the scope of this Agreement, the Merchant​     ​  is responsible for the supply of goods and/or services to be provided by the Merchant, the conditions, agreements and/or results associated with the supply of this good and/or service; PAPEL does not have any responsibility in these matters under any name.​ 

​​PAPEL is not a party to the commercial relationship (buying and selling of products or services) and disputes arising from this between the Merchant and the End User. PAPEL accepts, declares and undertakes that it is only obliged to submit ​information​ and documents under its own responsibility within the framework of the legislation for the resolution of disputes.​ 

​​PAPEL has no responsibility towards the End User within the scope of the Consumer Protection Law No. 6502 ​(“TKHK”)​, ​TCC​​​ and other relevant legislation.​ 

​​Merchant accepts that it has to comply with ​TKHK​​     ​ and relevant legislation in the sale of products or services, and in this context, Merchant is personally responsible for all kinds of losses and damages, including administrative sanctions and penalties. Merchant is personally responsible for the full and flawless presentation and fulfillment of its products or services, and for delivering the invoices and documents that must be submitted with the product or service.​ 

​​The sales transactions to be carried out by the Merchant over the internet are in the nature of a distance contract in accordance with the provisions of the legislation. Merchant will fully fulfill its obligations in accordance with the provisions of Law No. 6502, Regulation on Distance Contracts and other ​​legislation. In this context, the Merchant will have the ​End User​​     ​  approve the distance sales contract and the preliminary information form in compliance with the legislation, on the site where the sale takes place, before the Payment Transaction takes place via PAPEL.​ 

​​Merchant is obliged to follow the legal regulations regarding its sector and to comply with the restrictions regarding installments in the sale of its products or services. He/she accepts, declares and undertakes that he is solely responsible for the damages that may arise in case of violation, and that he will immediately eliminate the damages that may occur before PAPEL.​ 

​​PAPEL is not a party to any disputes that may arise between the Card Holder/End User and the Merchant for any reason, due to shopping made with Card/Pos and other reasons.​ 

​​Without prejudice to any legal and contractual rights and powers of PAPEL regarding Chargeback and other processes contained in the Agreement, the responsibility for any claims, requests, complaints and lawsuits that will be directed to PAPEL by the End User or other third parties due to the defect of the Merchant Member, as well as judicial decisions that will be made as a result, belongs to the Merchant Member. In case of danger of damage, PAPEL has the right and authority to suspend ​​the amount to be sent to the Merchant, only the amount that will correspond to the damage proven by PAPEL to have been caused by the fault of the Merchant, and to keep it as a guarantee.​ 

​​PAPEL may suspend the price paid by notifying the Merchant in the requests and complaints regarding withdrawal and refund directly communicated to it by the End User.​ 

​​In the event that End Users exercise their rights and powers arising from the relevant legislation despite the above items, and/or PAPEL is deemed responsible together/separately with the Merchant even if it is due to the legal regulation and/or PAPEL makes any payments due to the services within the scope of this Agreement and its annexes; Merchant, upon its first written request, pays PAPEL's costs that are on the agenda, as well as all kinds of losses that have arisen or will arise in any way, together with interest, taxes, all penalties, expenses and all kinds of other accessories, to PAPEL immediately, in cash and in full, PAPEL accepts, declares and undertakes, in advance and irrevocably, that it has the right of set-off and the right of recourse in these and related matters.​ 

​​PROMOTION, ANNOUNCEMENTS AND ADVERTISEMENTS​ 

​​With the signing of this Agreement, the Merchant ​​approves the sending of all kinds of commercial and non-commercial electronic messages by PAPEL.​     ​​ 

​​Merchant, trade name, brand, emblem and logo; To PAPEL for use by PAPEL in all kinds of advertisements, promotions and market researches, in the size, shape, form or place determined by PAPEL, It accepts, declares and undertakes that it gives permission as the right owner and authorizes PAPEL, and that no other permission or approval is required in this regard. Merchant agrees and undertakes to indemnify all kinds of damages and losses that PAPEL may incur and/or all amounts that it may have to pay due to violation of this statement (such as claims of intellectual property rights violations by third parties).​ 

​​PAPEL will not pay a fee to the Merchant for the use of these rights regarding the advertising and information activities it will carry out for promotional purposes. The rights of the parties regarding contracts and similar texts that they use or are using for their own activities belong to them. The Merchant agrees​​ to include explanations that the Parties are in a payment service relationship in its promotions and advertisements by PAPEL​​ during the term of the Agreement, and to provide the information about itself within the scope of this Agreement to the Banks with which PAPEL has signed a member merchant agreement.​ 

​​The Merchant must first obtain written approval from ​PAPEL​​     ​ for all kinds of information (editing details, terms of participation, pricing information and all similar issues) to be made to End Users regarding the PAPEL Services subject to the Agreement, and the granting of this approval by ​​PAPEL will not mean that the Merchant fully and completely fulfills its obligations determined by the legislation or this Agreement. It is the Merchant's responsibility to ensure that this information is complete, accurate and in compliance with the provisions of this Agreement and the law.​ 

​​In advertisements and promotions related to the ​PAPEL Services​​     ​ subject to the Agreement, the Parties may use the brand, logo, title and emblems of the Merchant, the service-related phrases, the brands, logos, titles and emblems of PAPEL or group companies, and the brand, logo, name, title and emblem of the End User Payment Service Provider and may use the emblems only with the prior written approval of the relevant party. PAPEL and Merchant may use their own brand, logo, title, emblem etc. without a valid reason. will not refrain from giving such approval with respect to its marks. The Merchant accepts, declares and undertakes that it will act in accordance with all applicable legislative regulations, especially the ​TKHK,​​     ​ the Regulation on Commercial Advertisements and Announcements and the Law on Intellectual and Artistic Works, in all kinds of advertising and promotional materials and other content to be prepared in relation to ​PAPEL​​     ​ Services or related goods and services.  Merchant will be solely responsible for any loss or damage that may arise due to the Merchant's breach of this obligation, and any amount that PAPEL will have to pay as a result of this behavior will be recourse to the Merchant for all requests made to PAPEL.​​ Merchant accepts, declares and undertakes that it will immediately fulfill these requests and will pay the relevant amounts to PAPEL together with all its accessories in cash at the first request.​ 

​​TERM AND TERMINATION OF THE AGREEMENT​ 

​​This Agreement enters into force on the date it is signed by the Parties and is valid for 1 (one) year from this date. Merchant may terminate the Agreement unilaterally at any time without giving any reason, by giving a written notice to PAPEL at least 30 (thirty) days before the end of the Agreement period. If the contract is not terminated as specified in this article, it automatically extends in one-year periods.​ 

​​​​Merchant may not terminate this Agreement unilaterally within 1 (one) year following the signing of the Agreement, unless there is a justifiable reason arising from PAPEL that renders the continuation of the Merchant substantially useless.​ 

​​In case the Merchant does not fulfill its obligations under this Agreement at all/duly or in a timely manner and violates ​​the Agreement, PAPEL, without prejudice to its right of immediate termination, by issuing a warning; will give the Merchant a period of 15 (fifteen) days​​​ ​for the fulfillment of the obligation and/or the elimination of the violation with all its consequences. If PAPEL determines that the obligation has not been fulfilled and/or the violation has not been eliminated with all its consequences at the end of this period, it is authorized to terminate this Agreement unilaterally. In this context, Merchant accepts, declares and undertakes that PAPEL is responsible for all kinds of damages that have arisen or will arise due to the breach of this Agreement and/or the termination of the Agreement, and that it will immediately indemnify this loss upon PAPEL's request. PAPEL reserves the right to deduct the aforementioned amounts from the receivables that have arisen or will arise.​ 

​​Merchant accepts that the rules regarding restrictions or prohibitions changed and/or new to be introduced by legal regulations or decisions of competent authorities will be followed, action will be taken in accordance with the information/warnings made to it by PAPEL and necessary information/warnings will be given to its personnel in this regard. The agreement of Member Merchants who are found to have acted in violation of the regulation in this article will be terminated by PAPEL immediately for just cause.​ 

​​Changes in the current dominant capital structure of the Merchant following the signature date of the Agreement, initiating legal proceedings against the Merchant or other organizations to which the Merchant is affiliated, in a way that will prevent or endanger the Merchant's fulfillment of its commitments arising from this Agreement, and filing a lawsuit, Notifying PAPEL about lien instructions about the Merchant or other organizations affiliated with the Merchant, requesting their bankruptcy, making concordat requests, The initiation of the liquidation procedures and the conclusion that the Merchant's fulfillment of its commitments arising from the Contract, as a result of the evaluation made by PAPEL at its own discretion, is in danger, is the reason for PAPEL to terminate the Contract immediately for justified reasons.​ 

​​Merchant, changes that occur/may occur in the partnership structure, change of address, transfer of business to third parties or change of ownership by any means, sale, leasing, significant suspension/suspension or suspension/suspension of the activities of the Merchant and written in the application form. / It is obliged to notify PAPEL of any change of information submitted to PAPEL on the same day via notary public or registered letter with return receipt (acknowledgment of receipt). In such cases, which will affect the continuation of the contractual relationship, PAPEL has the right to evaluate whether it will continue the contractual relationship and to terminate the contract immediately or before the term, if necessary, for just cause. In this context, if the Merchant enables third parties to benefit from the powers and opportunities granted to it in this Agreement, without the written approval of PAPEL, in any dispute that may arise between the third party and PAPEL, it is first degree against PAPEL and jointly with the third party, and accepts, declares and undertakes that it will be jointly and severally liable as the debtor.​ 

​​​In the event of termination of the Contract, the Merchant is obliged to return all printed documents, if any, POS equipment and other materials given to it by PAPEL in full and intact condition, within 7 (seven) days, to PAPEL. Merchant accepts, declares and undertakes in advance that it is responsible for the damages that may arise in case it does not fulfill this obligation, and to pay PAPEL the costs of these materials and documents to be determined by PAPEL or the costs shown on the delivery receipt. ​Termination or termination of the Agreement for any reason does not remove the rights and obligations of the Parties arising from the Agreement. Merchant is obliged to fulfill all kinds of obligations that it is obliged to perform due to the transactions carried out during the execution of this Agreement, even after the expiry of the Agreement, within the framework of the provisions of the Agreement and within the period.​ 

​​PENAL CLAUSE​ 

​​​     ​​​     ​​​     ​​​     ​​​     ​​​​Merchant, Article 7.15. and 7.23. Without prejudice to the provisions of this Agreement, in case of breach of the provisions of the Agreement, particularly the articles 4.1.26 and 4.1.32​​ , and/or failure to fulfill the obligations arising from the agreement, it shall be liable to pay the penalty clause of ​​ ​     ​​​[ ]​​​​, ​​​​ ​​in addition to the compensation, to PAPEL. ​​ PAPEL may deduct the penal clause amount from the payments to be made to the Merchant.​     ​​​​     ​​​​​ 

​​​ 

​​INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS​ 

​​​All intellectual property rights regarding Papel Services​​     ​  belong exclusively to PAPEL, especially the regulations regarding the placement of the Virtual POS Software on the ​Merchant's​​     ​ Website and the Mobile POS application for the execution of PAPEL Services; Nothing in this Agreement can be interpreted as granting a right, license or privilege to the Merchant on any software, application etc. intellectual product in question.​ 

​​All rights on the Merchant Payment Service Control Panel and system, service infrastructure and any related content and material, including software and codes, belong to PAPEL, and that it and its authorized personnel may partially or completely copy and reproduce the information obtained through the use of the services. It accepts, declares and undertakes that it is forbidden to allow its use by other people, to subject it to reverse engineering, etc. processes.​ 

​​Each Party agrees and undertakes that it will not violate the intellectual and industrial rights owned​     ​  or authorized by the other​     ​ Party, and that it will immediately notify the other Party​​​​ of any violations by third parties. No provision of this Agreement; It cannot be interpreted ​​as granting a right, license or privilege to the other Party on the intellectual and industrial rights owned or authorized by one of the parties.​ 

​​The Merchant's ability to use the brand, logo and title of PAPEL and/or other signs promoting PAPEL in any medium and under any​     ​ circumstances is subject to the prior written consent of PAPEL.​ 

​​The Merchant accepts in advance that it may use the trademark, logo, title and introductory signs associated with the Merchant, provided that it is limited to the ​PAPEL Services​​     ​ provided by PAPEL to the Merchant.​ 

​​PAPEL is free to change the content and design of its website and Payment Service on its own. Changes in the scope, domain name and application of the Merchant's Website, adding new websites that it wishes to benefit from ​​​​​​PAPEL Services, or using PAPEL Services offered by ​​​​PAPEL for different goods and services than agreed in the Agreement are subject to PAPEL's prior written approval. .​ 

​​PRIVACY AND PROTECTION OF PERSONAL DATA​ 

​​The Parties accept that the transactions to be carried out within the scope of this Agreement and other relevant documentation or the information to be acquired in connection therewith are in the nature of confidential information and no information will be disclosed to third parties without the written consent of the ​Parties​​     ​​     ​, except for the orders of the authorized official authorities. However, sharing information about PAPEL's Merchant Member with persons within the group of companies that PAPEL is a part of, both itself and their shareholders, subsidiaries and/or Banks, Card System organizations, correspondent bank, other suppliers, consultants, solution partners, support service organizations, external service providers and/or other financial institutions, if required under this Agreement, will not be considered a violation of this article. Merchant consents to such information and document sharing.​ 

​​Merchant, within the scope of the Regulation on Bank and Credit Cards No. 5464, KVKK and all relevant legal regulations, excluding persons, institutions and​ organizations​​     ​ authorized by law, who will protect the information obtained about the card and ​Card Holder​​     ​ as a result of the use of the card by complying with the provisions of the relevant legal regulation. shall not disclose, publish, publicly disclose or transfer to third parties in any way, process, make them available to third parties or institutions, or use them for their benefit, without the prior written consent of the ​ Card Holder.​​     ​ Merchant may not store and copy this information in any form or by any means, with the exception of the information and documents regarding the expenditure stored for collection purposes and the information and documents kept for the collection of periodic payments within the framework of the written approval given by the ​Card Holder.​​     ​  Member merchants cannot share, sell, buy or exchange their card information with persons or entities other than the establishment with which they have made a member merchant agreement.​ 

​​Merchant is under the obligation to inform the ​​Card Holders​​ about how and for what purposes they will record, store or process their information, to obtain the consent of the Card Holders in this regard and to inform the Card Holder immediately if these conditions change. Merchant will save, store or process such information only within the definition and scope of the ​PAPEL Service​​     ​  subject to this Agreement; It accepts, declares and undertakes that it will act in accordance with the KVKK in these transactions and will take all kinds of technical and administrative measures to ensure the level of security stipulated by the relevant legislation.​ 

​​PAPEL may use all kinds of information, including the usage information provided to it by the Merchant and/or provided during the ​PAPEL Services​​     ​ , for security reasons and/or to fulfill its legal and contractual obligations, in this context, it may disclose this information to the relevant third parties, if necessary, on a database. It can be classified and preserved, anonymized and used for purposes such as performance evaluation, statistical evaluation, creation of marketing campaigns, reporting. Merchant agrees that this information can be shared with business partners with whom it has business relations to the extent necessary for ​PAPEL Services.​​     ​​​ Merchant also accepts and declares that PAPEL may also submit information regarding transactions carried out through Merchant or ​PAPEL Service​​     ​ subject to the contract to the relevant authority/organization in cases where information and documents are requested by official authorities, especially the Central Bank, Banking Regulation and Supervision Agency.​ 

​​The approval and request logs of the transactions made through PAPEL ​Services​​     ​  and all kinds of information and documents related to the transactions can be requested by PAPEL from the Merchant. It is the Merchant's responsibility to promptly fulfill these requests. PAPEL also has the right to audit whether the commitments under this Agreement are fulfilled by the Merchant. ​Merchant​​     ​ also provides a solution to the requests for unauthorized use, returns etc. submitted by the End User Payment Service Provider or the bank with which PAPEL has an agreement to provide the services, and/or to the End User Payment Service Provider or PAPEL has an agreement to provide the services. accepts that it may be shared with the End User Payment Service Provider and the bank with which PAPEL has made an agreement to provide the services for the purpose of reporting to the bank regarding the transactions, and consents to the said sharing in advance. Documents regarding the transactions carried out within the scope of this Agreement will be kept by PAPEL for a minimum of 10 (ten) years following the transaction date, unless a longer period is specified in the legislation. ​​​​​ 

​​Regarding the sharing of personal data of the specified persons with third parties, that the personal data of Merchant officials, shareholders and relevant contact persons and End Users may be shared by ​​​​PAPEL with official authorities and/or other third parties so that PAPEL can fulfill its legal obligations and provide PAPEL Services. As a result, the Parties agree that the End Users will be informed by the Merchant within the scope stipulated in the KVKK and the necessary permissions and approvals will be obtained from these persons in the content and format in accordance with the aforementioned legislation.​ 

​​Merchant, the personal data shared with PAPEL is obtained, processed and transferred to PAPEL in accordance with the law and honesty rules, is correct and up-to-date, informs the relevant persons(data owner) in accordance with Article 10 of the KVKK in order to transfer this data to PAPEL, and  11. It accepts, declares and undertakes that it has informed PAPEL  about its rights in accordance with the article, that it has obtained their explicit consent when required by law, and that, upon PAPEL's request, it will provide PAPEL with documents proving that the relevant persons(data owner) have been informed and their consent has been obtained.​ 

​​Any request sent to the Merchant by the natural person, who will be qualified as the data owner within the scope of KVKK, and which will affect PAPEL's​     ​ processing of personal data or require any action on the personal data, will be notified to PAPEL immediately (within the same day). Merchant is also obliged to immediately fulfill the data subject requests communicated to it by PAPEL. Requests that cannot be fulfilled on the same day for any reason will be fulfilled within the following business day by stating the reason in writing.​ 

​​The obligations of the Parties​​ under this article will continue indefinitely even if this Agreement terminates.​ 

​​OTHER PROVISIONS​ 

​​​Prohibition of Transfer​: Merchant cannot transfer or transfer its rights and obligations arising or to arise from this Agreement to third parties in any form and in any way, and/or cannot establish a pledge/guarantee on them in favor of third parties, without the written consent of PAPEL. Such dispositions are not valid for PAPEL without the consent of PAPEL and cannot be claimed against PAPEL.​ 

​​In case the Merchant Internet Site is transferred to third parties, it informs PAPEL in writing within 1 (one) day at the latest. In the event that this situation occurs, Merchant accepts that PAPEL reserves the right to terminate the Agreement for just cause.​ 

​​​Force Majeure​: Merchant will cause PAPEL to partially or completely fulfill or delay its obligations under this Agreement, labor movements, embargoes, decisions of official authorities, government and public institutions, natural disasters, fire, earthquake, flood, epidemic and not limited to these. PAPEL accepts, declares and undertakes that it is not responsible for this delay and/or inability to fulfill it if it is caused by events beyond its control.​ The occurrence of force majeure shall not be interpreted as the termination of the obligations of the Merchant before the Force Majeure.​​ 

​​​Evidentiary Agreement​: ​The Parties may resolve disputes arising from the Agreement with the books and records of the Parties in written, oral or electronic media, etc. 193 of the HMK's records. accepts, declares and undertakes that there will be final and exclusive evidence within the meaning of the article.​​ 

​​​​​​​ 

​​​Evidence Contract​: The Parties accept, declare and undertake that the books and records of the Parties and their written, oral or electronic media etc. records will be final and exclusive evidence within the ​meaning​​     ​  of Article 193 of the CPC in the resolution of disputes arising from the Contract.​ 

​​Without prejudice to the notification provision in article 18/3 of the ​TCC​​     ​ , all kinds of notifications, information ​and​​     ​  announcements to be made in this Agreement regarding the execution of the work flow and the provision of ​PAPE​L​     ​ Services will be valid by PAPEL by making it to the Merchant through methods in accordance with the ​​legislation. ​     ​​ 

​​Merchant is obliged to promptly notify its addressees of any changes, information, announcements, procedures and other application principles that must be notified regarding the subject of this Agreement, and PAPEL has no responsibility in this regard.​ 

​​​Changes​: PAPEL has the right to unilaterally make changes in the fee and commission rates and/or the conditions of the system due to the legislation to which it is subject and/or changes in the Banks and other transaction partners and contracted institutions. PAPEL shall notify and implement the changes within this scope to the Merchant by e-mail and/or other appropriate methods to be determined, at the latest on the date of change or 1 (one) day before. PAPEL notifies the Merchant of any other amendments to the Agreement in a reasonable time, without prejudice to the provisions regarding the amendments specially arranged in the Agreement. In the notification to be made, the scope of the change and its effective date, that the Merchant has the right to terminate the Agreement without paying any fee until this date, and that the change will be deemed accepted if no objection is made within the period. If the Merchant does not object to the change, it is deemed to have accepted the change.​ 

​​​Taxes, Fees and Other Financial Obligations​: The Merchant is responsible for all kinds of expenses, stamp duty, expense tax, ​Fund​​     ​  and other taxes, duties and fees, and delays and other penalties incurred by all transactions to be made within the scope of this Agreement, and if requested and will provide to PAPEL the documents proving that it has made the payments. PAPEL is authorized to debit the Merchant account for these fees and ​​to deduct these fees from the receivables of the Merchant. Merchant accepts and undertakes that it will pay PAPEL all kinds of execution, litigation costs and attorney's fees in case of legal proceedings or lawsuits filed by PAPEL if it fails to pay its debts and fulfill its obligations arising from this Agreement.​ 

​​​Resolution of Disputes and Applicable Law​​: Istanbul Central (Çağlayan) Courts and Enforcement Offices will be authorized in case of disputes arising from this Agreement.​​​     ​​​ 

​​​Severability of Provisions​: If any provision of this Agreement becomes invalid, illegal or unenforceable for any reason, it will be interpreted and applied as closely as it would have been if it had not been for the invalidity, unenforceability or illegality. The existence of such invalid, unenforceable or illegal provision shall not affect the validity, legality and enforceability of the other provisions.​ 

​​In addition, the different application of any of the conditions in this Agreement ​​by the Parties will not mean that that article has been accepted as it is, and the said practice will not affect the validity of other articles. In addition, the non-implementation of some articles shall not be interpreted as the repeal of the said articles.​ 

​​​Annexes​: The information, which is an integral part of this Agreement, or which will be notified to the Merchant again later by PAPEL and which may change from time to time, is an integral part of the Agreement. Merchant is obliged to monitor such explanations, updates and changes regarding these explanations, and it has been accepted by the Parties that the relevant content is constantly and regularly monitored and known ​​by the Merchant.​ 

​​This Agreement consists of 14 (fourteen) articles and the following annexes; It has been signed by the Parties​​ in 2 (two) copies on …/…/… by negotiating and agreeing on all its provisions.​ 

​​APPENDIX​ 

​​APPENDIX-1: APPLICATION FORM ​ 

​​ANNEX-2: PROHIBITED ACTIVITIES​ 

​​ANNEX-3: FEES, COMMISSIONS and TERMS AND PENAL CONDITIONS​ 

​​​ 
      </Modal>
    </>
  );
};

export default AgreementModal;
