import {
    Header,
    HelpBox,
    SectionContact,
    Footer,
    Collapse,
    ProductBox,
} from "../../components";
import React from "react";
import {Col, Row} from "antd";
import {HelpBoxes, Questions} from "../../utilities/DummyContent";
import './help.scss'
import {Swiper} from "swiper/react";
import {SwiperSlide} from "swiper/react";


const HelpPage = () => {
    return (
        <>
            <Header type="white"/>
            <section className="section-help">
                <div className="container">
                    <Row align="middle" gutter={24}>
                        <Col span={24}>
                            <h1 className="text--h3 text--dark">How can we help you</h1>
                        </Col>
                    </Row>
                    <Row className="ant-row--swiper">
                        <Col>
                            <Swiper
                                className="swiper--help-boxes"
                                spaceBetween={22}
                                slidesPerView="auto"
                                slidesPerGroup={1}
                                loop={true}
                                slidesOffsetBefore={24}
                                initialSlide={1}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                breakpoints={{
                                    1248: {
                                        slidesPerView: 6,
                                        spaceBetween: 24,
                                        slidesOffsetBefore:0,
                                        loop: false,
                                    }
                                }}>
                                { HelpBoxes.map( (item, key) => (
                                    <SwiperSlide key={`help-boxes-slide-${key}`}>
                                        <HelpBox title={item.title} image={item.image} />
                                    </SwiperSlide>
                                )) }
                            </Swiper>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Collapse data={Questions} type="dark" />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col lg={12} span={24}>
                            <ProductBox compType="custom"
                                        type={false}
                                        className="product-box--agreements"
                                        title="User agreements"
                                        image={<figure><img src="images/agreements.png" srcSet="images/agreements-2x.png 2x, images/agreements.png 1x" alt="product exclusive"/></figure>}
                                        icons={[]}>
                            </ProductBox>
                        </Col>
                        <Col lg={12} span={24}>
                            <ProductBox type={false}
                                        className="product-box--github"
                                        title="Github Developer Room"
                                        image={<figure><img src="images/github.png" srcSet="images/github-2x.png 2x, images/github.png 1x" alt="product exclusive"/></figure>}
                                        icons={[]}>
                                <button>
                                    <figure>

                                        <img src="images/gradient-arrow-right-up.png" srcSet="images/gradient-arrow-right-up-2x.png 2x, images/gradient-arrow-right-up.png 1x" alt="Arrow"/>
                                    </figure>
                                </button>
                            </ProductBox>
                        </Col>
                    </Row>
                </div>
            </section>
            <SectionContact/>
            <Footer />
        </>
    )
}

export default HelpPage;