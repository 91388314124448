import * as yup from "yup";

export const validationSchema = yup.object().shape({
  name: yup.string().required("Enter a name please!"),
  email: yup.string().email("Please enter a valid email").required("Enter an email address please!"),
  phone_number: yup.number().positive().integer().required("Enter a valid phone number please!"),
  interested: yup.string().required("Enter what you are interested in please!"),
  checked: yup.boolean().required("The User Agreement must be accepted.").oneOf([true], "The User Agreement must be accepted."), 
  // recaptcha: yup.string().min(1, 'Prove You Are Not A Robot').required('Prove You Are Not A Robot'),



});