
import { Slider } from 'antd';
import {useState} from "react";
import { motion } from 'framer-motion';
import { phoneTextEvenVariants, textVariants } from '../../utilities/Config';

const RangeSlider = ({ onChange }) => {
    const [sliderValue, setSliderValue] = useState(1);

    const onChangeFunc = (newValue) => {
        setSliderValue(newValue);
        onChange(newValue)
    };

    return (
        <motion.div
        variants={textVariants}
        initial="hidden"
        viewport={{ once: true, amount: 0.1 }}
        whileInView="whileInView"
        className="range-slider">
            <motion.span variants={phoneTextEvenVariants} className="range-slider__text">$200</motion.span>
            <Slider
                min={1}
                max={20}
                tooltip={{ open: false }}
                onChange={onChangeFunc}
                value={typeof sliderValue === 'number' ? sliderValue : 0}
            />
        </motion.div>
    )
}

export default RangeSlider;