import React from 'react'
import PropTypes from "prop-types";
import classNames from "classnames";

const ButtonLink = ({size, className, href, iconLeft, iconRight, onlyIcon, center, type, compType = 'button', ...props}) => {
    return (
        <a href={href} className={classNames('button', className, type && `button--${type}`,
            {
                'button--small': size === 'small',
                'button--big': size === 'big',
                'button--center': center,
                'button--left-icon': iconLeft && !iconRight,
                'button--right-icon': iconRight && !iconLeft,
                'button--both-icon': iconRight && iconLeft,
                'button--only-icon': onlyIcon,
            }
        )} {...props}>
            {iconLeft ? iconLeft : null}
            <span>{props.children}</span>
            {iconRight ? iconRight : null}
        </a>
    )
}

ButtonLink.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
    center: PropTypes.bool,
    children: PropTypes.any,
    onlyIcon: PropTypes.bool,
    iconLeft: PropTypes.element,
    iconRight: PropTypes.element,
}

export default ButtonLink;




