import { useState, useEffect } from "react";

export default function useMatchMedia( size = 768 ) {

    const [isMobile, setIsMobile] = useState();

    useEffect(() => {

        const matchWatcher = window.matchMedia(`(max-width: ${size}px)`);
        setIsMobile( matchWatcher.matches );
        matchWatcher.addEventListener('change', update);

        function update( e ) {

            if ( e.matches ) {
                setIsMobile( true );
            } else {
                setIsMobile( false );
            }
        }

        return function cleanup() {
            matchWatcher.removeListener( update );
        }

    }, [size]);

    return [isMobile];

}