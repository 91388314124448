import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import {
  Button,
  Footer,
  Header,
  ProductBox,
  SectionContact,
  SectionQuestion,
} from "../../components";
import "./business.scss";
import handleScroll from "../../utilities/Functions/handleScroll";
import {
  textVariants,
  phoneTextVariants,
  phoneTextEvenVariants,
  globalVariants,
  imagesVariants,
  phoneNotificationVariants,
  rotateXVariants,
  chatMessageVariants,
} from "../../utilities/Config";

const Business = () => {
  const [position, setPosition] = useState(null);

  let location = useLocation();

  useEffect(() => {
    const target = location?.state?.targetFragment;
    target &&
      handleScroll(document.getElementById(target).getBoundingClientRect().top);
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setPosition(document.getElementById("section-contact").offsetTop);
    }, 1000);
  }, []);

  return (
    <>
      <Header type="white" />
      <motion.section
        variants={globalVariants}
        initial="hidden"
        viewport={{ once: true, amount: 0.1 }}
        whileInView="whileInView"
        className="section-businesspage"
      >
        <div className="container">
          <Row className="text-center">
            <Col>
              <motion.h2
                variants={textVariants}
                className="text--h2 text--dark"
              >
                Find your financial needs, stores, costumers and 
                <span className="text--gradient-pink">employs</span>
              </motion.h2>
              <motion.h6
                variants={phoneTextVariants}
                className="text--subtitle"
              >
                Manage bookings, payments, and staff on a powerful handheld
                device with Papel Appointments on Papel Terminal 
              </motion.h6>
            </Col>
          </Row>
        </div>
      </motion.section>
      <motion.section className="section-man">
        <div className="container">
          <Row className="img-row">
            <Col
              md={{
                span: 20,
              }}
            >
              <motion.figure
                variants={imagesVariants}
                initial="hidden"
                animate="whileInView"
              >
                <img
                  src="images/business-man.png"
                  srcSet="images/business-man-2x.png 2x, images/business-man.png 1x"
                  alt="business man"
                />
              </motion.figure>
            </Col>
          </Row>
          <Row id="section-send-receive" className="text-center">
            <Col>
              <motion.h3
                variants={chatMessageVariants}
                initial="hidden"
                whileInView="whileInView"
                viewport={{ once: true, amount: 0.1 }}
                className="text--h3"
              >
                All <span className="text--gradient-pink">financial</span> need
              </motion.h3>
            </Col>
          </Row>
          <Row>
            <Col className="grid-template" md={24} xs={24} sm={24}>
              <ProductBox
                id="send-receive"
                className="product--send-receive"
                horizontal
                icons={[]}
                boxType="custom"
                type=""
                title={
                  <span className="text--h3 text--white">Send & Receive</span>
                }
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/send-receive.png"
                      srcSet="images/send-receive-2x.png 2x, images/send-receive.png 1x"
                      alt="product terminals"
                    />
                  </motion.figure>
                }
              ></ProductBox>
              <ProductBox
                type={false}
                boxType="custom"
                icons={[]}
                className="product-box--virtual"
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/virtual-bg.png"
                      srcSet="images/virtual-bg-2x.png 2x, images/virtual-bg.png 1x"
                      alt="product secure"
                    />
                  </motion.figure>
                }
                title={<span className="text--h3 text--dark">Virtual Pos</span>}
              ></ProductBox>
              <ProductBox
                type={false}
                boxType="light"
                vertical
                className="product-box--payment"
                title={<span className="text--h3 text--dark">Payment</span>}
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/payment-product.png"
                      srcSet="images/payment-product-2x.png 2x, images/payment-product.png 1x"
                      alt="product cashback"
                    />
                  </motion.figure>
                }
                icons={[]}
              ></ProductBox>
              <ProductBox
                type={false}
                boxType="custom"
                icons={[]}
                className="product-box--paylink"
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/product-paylink.png"
                      srcSet="images/product-paylink-2x.png 2x, images/product-paylink.png 1x"
                      alt="product secure"
                    />
                  </motion.figure>
                }
                title={
                  <>
                    <span className="text--h3 text--white">Pay with Link</span>
                  </>
                }
              ></ProductBox>
            </Col>
          </Row>
        </div>
      </motion.section>
      <motion.section
        variants={globalVariants}
        initial="hidden"
        viewport={{ once: true, amount: 0.1 }}
        whileInView="whileInView"
        className="section-business-terminal"
      >
        <div className="container">
          <Row className="text-center">
            <Col md={16}>
              <motion.h3
                variants={textVariants}
                className="text--h3 text--dark"
              >
                Business Terminal
              </motion.h3>
              <motion.h4
                variants={phoneTextEvenVariants}
                className="text--h4 text--dark"
              >
                At Papel, we understand the importance of security. That's why
                we prioritize protecting your personal information and finances
                when you use our financial applications. Rest assured that your
                security is our top concern.
              </motion.h4>
            </Col>
          </Row>
          <Row gutter={[34, 24]}>
            <Col md={12} sm={24} xs={24}>
              <ProductBox
                type={false}
                boxType="custom"
                icons={[]}
                className="product-box--terminal-1"
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/terminal-1.png"
                      srcSet="images/terminal-1-2x.png 2x, images/terminal-1.png 1x"
                      alt="product secure"
                    />
                  </motion.figure>
                }
                title={
                  <div className="text-center text-group">
                    <span className="text--h3 text--white">Android POS</span>
                    <Button
                      onClick={() => handleScroll(position)}
                      className="button--link--dark"
                      iconRight={<i className="icon-arrow-right-line"></i>}
                    >
                      Apply
                    </Button>
                  </div>
                }
              ></ProductBox>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <ProductBox
                type={false}
                boxType="custom"
                icons={[]}
                className="product-box--terminal-2"
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/terminal-2.png"
                      srcSet="images/terminal-2-2x.png 2x, images/terminal-2.png 1x"
                      alt="product secure"
                    />
                  </motion.figure>
                }
                title={
                  <div className="text-center text-group">
                    <span className="text--h3 text--dark">Virtual POS</span>
                    <Button
                      onClick={() => handleScroll(position)}
                      className="button--dark"
                      iconRight={<i className="icon-arrow-right-line"></i>}
                    >
                      Apply
                    </Button>
                  </div>
                }
              ></ProductBox>
            </Col>
          </Row>
          <Row gutter={[34, 24]}>
            <Col md={12} sm={24} xs={24}>
              <ProductBox
                type={false}
                boxType="custom"
                icons={[]}
                className="product-box--terminal-3"
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/terminal-3.png"
                      srcSet="images/terminal-3-2x.png 2x, images/terminal-3.png 1x"
                      alt="product secure"
                    />
                  </motion.figure>
                }
                title={
                  <div className="text-center text-group">
                    <span className="text--h3 text--dark">MPOS</span>
                    <Button
                      onClick={() => handleScroll(position)}
                      className="button--dark"
                      iconRight={<i className="icon-arrow-right-line"></i>}
                    >
                      Apply
                    </Button>
                  </div>
                }
              ></ProductBox>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <ProductBox
                type={false}
                boxType="custom"
                icons={[]}
                className="product-box--terminal-4"
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/terminal-4.png"
                      srcSet="images/terminal-4-2x.png 2x, images/terminal-4.png 1x"
                      alt="product secure"
                    />
                  </motion.figure>
                }
                title={
                  <div className="text-center text-group">
                    <span className="text--h3 text--dark">Soft POS</span>
                    <Button
                      onClick={() => handleScroll(position)}
                      className="button--dark"
                      iconRight={<i className="icon-arrow-right-line"></i>}
                    >
                      Apply
                    </Button>
                  </div>
                }
              ></ProductBox>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <ProductBox
                type={false}
                boxType="custom"
                icons={[]}
                className="product-box--terminal-5"
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/terminal-5.png"
                      srcSet="images/terminal-5-2x.png 2x, images/terminal-5.png 1x"
                      alt="product secure"
                    />
                  </motion.figure>
                }
                title={
                  <div className="text-group">
                    <span className="text--h3 text--white">P2 SE</span>
                  </div>
                }
              >
                <Button
                  onClick={() => handleScroll(position)}
                  className="button--link--dark"
                  iconRight={<i className="icon-arrow-right-line"></i>}
                >
                  Apply
                </Button>
              </ProductBox>
            </Col>
          </Row>
        </div>
      </motion.section>
      <motion.section
        variants={phoneNotificationVariants}
        className="section-special-notifications"
      >
        <Row gutter={24} className="text-center">
          <Col>
            <motion.h2
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              variants={phoneTextVariants}
              className="text--h1"
            >
              Send <span className="text--gradient-pink">special</span>
              <br />
              notifications
            </motion.h2>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col>
            <motion.figure
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              variants={phoneNotificationVariants}
            >
              <img src="images/phone-1.png" alt="phone 1" />
            </motion.figure>
          </Col>
        </Row>
        <Row gutter={24} className="text-center">
          <Col>
            <motion.h2
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              variants={phoneTextEvenVariants}
              className="text--h2"
            >
              to your costumers <br /> define campaigns
            </motion.h2>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col>
            <motion.figure
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              variants={phoneNotificationVariants}
            >
              <img src="images/phone-2.png" alt="phone 2" />
            </motion.figure>
          </Col>
        </Row>
        <Row gutter={24} className="text-center">
          <Col></Col>
        </Row>
        <Row gutter={24}>
          <Col>
            <motion.figure
              initial="hidden"
              viewport={{ once: true, amount: 0.1 }}
              whileInView="whileInView"
              variants={phoneNotificationVariants}
            >
              <img src="images/phone-3.png" alt="phone 3" />
            </motion.figure>
          </Col>
        </Row>
        <div className="bg-grey">
          <Row gutter={24} className="text-center ">
            <Col>
              <motion.h2
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                variants={phoneTextVariants}
                className="text--h2 text--dark"
              >
                creating <br /> a campaign
              </motion.h2>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <motion.figure
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                variants={phoneNotificationVariants}
              >
                <img src="images/phone-4.png" alt="phone-4" />
              </motion.figure>
            </Col>
          </Row>
        </div>
        <div className="bg-white">
          <Row gutter={24} className="text-center ">
            <Col>
              <motion.h2
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                variants={phoneTextEvenVariants}
                className="text--h2 text--dark"
              >
                it is very <br /> simple
              </motion.h2>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <motion.figure
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                variants={phoneNotificationVariants}
              >
                <img src="images/phone-5.png" alt="phone 5" />
              </motion.figure>
            </Col>
          </Row>
        </div>
      </motion.section>
      <motion.section
        variants={globalVariants}
        initial="hidden"
        viewport={{ once: true, amount: 0.1 }}
        whileInView="whileInView"
        className="section-foryourteam"
      >
        <div className="container">
          <Row>
            <Col md={12}>
              <Row gutter={[24, 24]} className="frame-column">
                <Col span={12} className="frame-column__left">
                  <motion.figure variants={phoneTextVariants}>
                    <img src="images/frame-roles.png" alt="frame roles" />
                  </motion.figure>
                  <motion.figure variants={phoneTextVariants}>
                    <img src="images/frame-advance.png" alt="frame advance" />
                  </motion.figure>
                </Col>
                <Col span={12} className="frame-column__right">
                  <motion.figure variants={phoneTextVariants}>
                    <img
                      src="images/frame-add-limits.png"
                      alt="frame add limirs"
                    />
                  </motion.figure>
                  <motion.figure variants={phoneTextVariants}>
                    <img src="images/frame-add-card.png" alt="add card" />
                  </motion.figure>
                </Col>
              </Row>
            </Col>
            <Col
              md={{
                span: 10,
                offset: 2,
              }}
              xs={24}
              sm={24}
            >
              <motion.h2
                variants={textVariants}
                className="text--h2 text--dark"
              >
                for your team and employees
              </motion.h2>
              <motion.h5
                variants={phoneTextEvenVariants}
                className="text--h5 text--dark"
              >
                At Papel, we understand the importance of security. That's why
                we prioritize protecting your personal information and finances
                when you use our financial applications. Rest assured that your
                security is our top concern.
              </motion.h5>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col md={10} sm={24} xs={24}>
              <motion.h3
                variants={phoneTextVariants}
                className="text--h3 text--dark "
              >
                All in one for your
                <span className="text--gradient-pink">business</span>
              </motion.h3>
            </Col>
            <Col md={10} sm={24} xs={24}>
              <motion.h5
                variants={phoneTextEvenVariants}
                className="text--h5 text--dark text-right"
              >
                At Papel, we understand the importance of security.
              </motion.h5>
            </Col>
          </Row>
          <Row className="frame-merchant">
            <Col md={20}>
              <figure>
                <motion.img
                  variants={rotateXVariants}
                  initial="hidden"
                  whileInView="whileInView"
                  viewport={{ once: true, amount: 0.1 }}
                  src="images/frame-merchant.png"
                  alt="frame merchant"
                />
              </figure>
            </Col>
          </Row>
        </div>
      </motion.section>
      <SectionQuestion />
      <SectionContact />
      <Footer />
    </>
  );
};

export default Business;
