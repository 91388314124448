const globalVariants = {
    hidden: {
        opacity: 0,
    },
    whileInView: {
        opacity: 1,
        transition: {
            staggerChildren: .1,
        }
    },
    whileInViewSecond: {
        opacity: 1,
        transition: {
            staggerChildren: .8,
        }
    },
    whileInViewThird: {
        opacity: 1,
        transition: {
            staggerChildren: 1,
        }
    },
}
const heroCardVariants = {
    hiddenLeft: {
        opacity: 0,
        x: '-100vw'
    },
    hiddenRight: {
        opacity: 0,
        x: '100vw'
    },
    visible: {
        opacity: 1,
        x: '-50%',
        transition: {
            delay: .4,
            type: "spring",
            duration: 4,
        },
    }
}
const textVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: .4,
            type: "spring",
            duration: 4,
        },
    },
    whileInView: {
        opacity: 1,
        transition: {
            delay: .8,
            type: "spring",
            duration: 2,
            stiffness: 200,
        }
    },
    whileInViewOneSecDelay: {
        opacity: 1,
        transition: {
            delay: .1,
            type: "spring",
            duration: 4,
        }
    }
}
const phoneContainerVariants = {
    hidden: {
        opacity: 0,
        y: 0,
    },
    whileInView: {
        opacity: 1,
        y: -200,

        transition: {
            when: 'beforeChildren',
            staggerChildren: .1,
            duration: 5
        }
    },
}
const productBoxContainerVariants = {
    hidden: {
        opacity: 0,
        
    },
    whileInView: {
        opacity: 1,
       
        transition: {
            when: 'beforeChildren',
            staggerChildren: .1,
        }
    }
}
const imagesVariants = {
    hidden: {
        opacity: 0,
    },
    whileInView: {
        opacity: 1,
        transition: {
            duration: 1.5
        }
    }
}
const chatMessageVariants = {
    hidden: {
        opacity: 0,
        y: 70
    },
    whileInView: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.6,
            duration: 0.8,
        }
    }
}

const rotateXVariants = {
    hidden: {
        opacity: 0,
        rotateX: 60
    },
    whileInView: {
        opacity: 1,
        rotateX: 0,
        transformStyle: "preserve-3d",
        transition: {
            duration: .8,
        }
    },
}
const rotateXVariantsBottom = {
    hidden: {
        opacity: 0,
        rotateX: -60
    },
    whileInView: {
        opacity: 1,
        rotateX: 0,
        transformStyle: "preserve-3d",
        transition: {
            duration: .8,
        }
    },
}
const rotateYVariants = {
    hidden: {
        opacity: 0,
        rotateY: 180
    },
    whileInView: {
        opacity: 1,
        rotateY: 0,
        transformStyle: "preserve-3d",
        transition: {
            duration: 1.5,
        }
    },
}
const phoneTextVariants = {
    hidden: {
        opacity: 0,
        x: -200
    },
    whileInView: {
        opacity: 1,
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
        }
    },
    whileInViewSecond: {
        opacity: 1,
        x: 0,
        viewport: {
            once: true,
            amount: .1
        },
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
        }
    },
}
const phoneTextEvenVariants = {
    hidden: {
        opacity: 0,
        x: 200
    },
    whileInView: {
        opacity: 1,
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
        }
    },
}
const phoneBoxVariants = {
    hidden: {
        opacity: 0,
        y: 20
    },
    whileInView: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.6,
            duration: 0.8,
        }
    },
}
const arrowVariants = {
    hidden: {
        opacity: 0,
        x: 0,
        y: 0,
    },
    whileInView: {
        opacity: 1,
        x: 160,
        y: -250,
        transition: {
            duration: .7,
        }
    },
}
const accessoryVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
    },
    whileInView: {
        opacity: 1,
        scale: [1.2, 1, 1.2, 1],
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
        }
    },
}
const topToBottomVariants = {
    hidden: {
        opacity: 0,
        y: -90
    },
    whileInView: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.6,
            duration: 2,
            rotateX: 45
        }
    },
}
const phoneNotificationVariants = {
    hidden: {
        opacity: 0,
        y: 300,
    },
    whileInView: {
        opacity: 1,
        y: 0,

        transition: {
            when: 'beforeChildren',
            staggerChildren: .3,
            duration: .3,
        }
    },
}

const cardVariants = {
    hidden: {
        opacity: 0,
        y: 0,
        x: '-50%'
    },
    whileInView: {
        opacity: 1,
        y: '100vh',
        x: '-50%',
        transition: {
            delay: 2,
            duration: 4,
        }
    },
}

const posCardVariants = {
    hidden: {
        opacity: 0,
        y: 70,
    },
    whileInView: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1.2,
        }
    }
}

export { heroCardVariants, phoneNotificationVariants, globalVariants, phoneContainerVariants, rotateXVariants, rotateYVariants, rotateXVariantsBottom, productBoxContainerVariants, imagesVariants, chatMessageVariants, textVariants, phoneTextVariants, phoneTextEvenVariants, phoneBoxVariants, arrowVariants, accessoryVariants, topToBottomVariants, cardVariants, posCardVariants }
