import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import {motion} from "framer-motion";


const ActionMenu = ({icon, text, type, passive, ...props}) => {
    return (
        <motion.div className={classNames('action-menu',
            {
                'action-menu--green': type === 'green',
                'action-menu--pink': type === 'pink',
                'action-menu--aqua': type === 'aqua',
                'action-menu--passive': passive,
            }
        )} {...props}>
            <i className={`action-menu__logo icon-${icon}`}></i>
            <span className="action-menu__text">{text}</span>
            <i className="action-menu__action icon-arrow-right"></i>
        </motion.div>
    )
}

ActionMenu.propTypes = {
    icon: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string
}

export default ActionMenu;