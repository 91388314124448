import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import handleScroll from "../../utilities/Functions/handleScroll";
import { globalVariants, chatMessageVariants, phoneTextVariants, phoneTextEvenVariants} from "../../utilities/Config";
import {motion} from "framer-motion";
import ButtonLink from "../Button/Link";



const Links = [
    {
        title: 'PERSONAL',
        links: [
            {
                title: 'Features',
                href: '/personal'
            }
        ]
    },
    {
        title: 'BUSINESS',
        links: [
            {
                title: 'Features',
                href: '/business',
                target: 'section-send-receive'
            }
        ]
    },
    {
        title: 'HELP',
        links: [
            {
                title: 'F.A.Q',
                href: '/help'
            }
        ]
    },
    {
        title: 'CARDS & PLANS',
        links: [
            {
                title: 'All Cards',
                href: '/cards'
            }
        ]
    },
]
const Supporters = [
    {
        src: 'images/todeb.png',
        alt: 'Todeb'
    },
    {
        src: 'images/merkez-bank.png',
        alt: 'Merkez Bankasi'
    },
    {
        src: 'images/visa.png',
        alt: 'Visa'
    },
    {
        src: 'images/mastercard.png',
        alt: 'Mastercard'
    },
    {
        src: 'images/dss.png',
        alt: 'Dss'
    },
]

const Footer = () => {
    const [position, setPosition] = useState(null)

    const handlePosition = () => {
        setPosition(document.getElementById('section-contact').getBoundingClientRect().top)
    }

    useEffect(() => {
        window.addEventListener('load', handlePosition);
        return () => window.removeEventListener('load', handlePosition);
    }, []);

    return (
        <motion.footer className="footer"
        variants={globalVariants}
        initial="hidden"
        // viewport={{once: true, amount: .1}}
        whileInView="whileInView">
            <div className="container">
                <div className="footer__top-left">
                    <motion.figure variants={chatMessageVariants}>
                        <img src="images/papel-logo-white.png" srcSet="images/papel-logo-white@2x.png 2x, images/papel-logo-white.png 1x" alt="Logo"/>
                    </motion.figure>
                    <motion.span className="text--caption">
                        Visit Papel Store in App Markets
                    </motion.span>
                    <motion.div variants={chatMessageVariants} className="button-group">
                        <ButtonLink href={"https://play.google.com/store/apps/details?id=com.papelmobile"} type="primary" iconLeft={<i className="icon-google-fill"></i>}>Play Store</ButtonLink>
                        <ButtonLink href={"https://apps.apple.com/tr/app/papel-türkiye/id1643288351"} type="primary" iconLeft={<i className="icon-apple-fill"></i>}>App Store</ButtonLink>
                    </motion.div>
                </div>
                <div className="footer__top-right">
                </div>
                <motion.div 

                className="footer__middle-left">
                    {Links.map((item, key) => (
                        <motion.div variants={phoneTextVariants}  key={`footer-links-${key}`}>
                            <span  className="text--decorative-2">{item.title}</span>
                            {item.links.map((item, key) => (
                                item.button ?
                                    <motion.span variants={phoneTextVariants} className="text--caption footer__link"
                                          onClick={() => handleScroll(position)}>{item.title}{console.log(position, 'foot position')}</motion.span>
                                    :
                                    <Link to={item.href}
                                          state={{ targetFragment: item?.target }}
                                          key={`footer-links-captions-${key}`}
                                          className="text--caption">{item.title}</Link>
                            ))}
                        </motion.div>
                    ))}
                </motion.div>
                <div
                
                className="footer__middle-right">
                    {Supporters.map((item, key) => (
                        <motion.figure
                        variants={phoneTextEvenVariants}  key={`supporters-${key}`}>
                            <img src={item.src} alt={item.alt}/>
                        </motion.figure>
                    ))}
                </div>
                <div className="footer__bottom">
                    <div className="footer__bottom__links">
                    </div>
                    <motion.div variants={chatMessageVariants} className="footer__bottom__social">
                        <Link target="_blank" to="https://instagram.com/papelturkiye?igshid=YmMyMTA2M2Y="><i className="icon-instagram"></i></Link>
                        <Link target="_blank" to="https://twitter.com/papelturkiye"><i className="icon-twitter"></i></Link>
                        <Link target="_blank" to="https://www.linkedin.com/company/papeltürkiye/"><i className="icon-linkedin"></i></Link>
                    </motion.div>
                </div>
                <motion.span variants={chatMessageVariants} className="text--caption footer__copyright">© Copyright 2023 Papel - A new way of money management</motion.span>
            </div>
        </motion.footer>
    )
}

export default Footer;