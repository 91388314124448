import { Collapse } from 'antd';
import PropTypes from "prop-types";
import "./accordion.scss"

const { Panel } = Collapse;

const Accordion = ({ data }) => {
    return <div className='accordion'>
        <Collapse defaultActiveKey={['0']} accordion className='accordion__content'>
            {data.map((item, key) => {
                return <Panel showArrow={false} header={item.title} key={key}>
                    <p className='text--p'>{item.text}</p>
                    <div className='accordion__img-area'>
                        {item.child}
                    </div>
                </Panel>
            })}
        </Collapse>
    </div>
}

export default Accordion;

Accordion.propTypes = {
    data: PropTypes.array
}
