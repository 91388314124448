import {Button, Footer, Header, Person} from "../../components";
import React from "react";
import {Col, Row} from "antd";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination} from "swiper";
import './about.scss'
import {TeamMembers} from "../../utilities/DummyContent";

SwiperCore.use([Pagination]);

const AboutPage = () => {
    return (
        <div className="about-page">
            <Header/>
            <section className="section-about">
                <div className="container">
                    <h3 className="text--h3">Join the future with <span className="text--gradient-v4">Papel</span></h3>
                    <p className="text--h4">Papel is a financial technology brand that offers customer-oriented solutions and tries to provide you with new and reliable financial experiences. </p>
                </div>
            </section>
            <section className="section-know-us">
                <div className="container">
                    <Row>
                        <Col lg={8} span={24}>
                            <h3 className="text--h3">Get To Know Us Better</h3>
                            <Button type="primary" iconRight={<i className="icon-arrow-right-line"></i>}>Linkedin</Button>
                        </Col>
                        <Col lg={{ span: 10, offset: 6 }} span={24}>
                            <p className="text--caption">Papel consist of a management team with experience over 20 years in the industry. Our team has set out to offer a space where they can successfully manage their financial situations and financial technology which is one of the most dynamic sectors of our time, to every individual and business, from the most remote corner of the country to the most central parts.</p>
                            <Person type="small" image="images/person-small.png" name="Jaxson Herwitz" title="HR Manager" />
                            <p className="text--h6">“<br/>Papel consist of a management team with experience over 20 years in the industry. Our team has set out to offer a space where they can successfully manage their financial situations and financial technology which is one of the most dynamic sectors of our time, to every individual and business, from the most remote corner of the country to the most central parts.</p>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="section-numbers">
                <div className="container">
                    <Row>
                        <Col span={24}>
                            <span className="text--xl">$5 M+</span>
                        </Col>
                        <Col xl={10} span={24}>
                            <span className="text--h6">Our team has over $5 billion of combined transaction experience</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <span className="text--xl text--gradient-stroke">$200K+</span>
                        </Col>
                        <Col xl={10} span={24}>
                            <span className="text--h6">Our team has over $5 billion of combined transaction experience</span>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="section-team">
                <div className="container">
                    <Row>
                        <Col lg={8} md={10} span={24}>
                            <h3 className="text--h3">Meet our amazing team</h3>
                        </Col>
                        <Col lg={{ span: 10, offset: 6 }} md={14} span={24}>
                            <p className="text--caption">Papel consist of a management team with experience over 20 years in the industry. Our team has set out to offer a space where they can successfully manage their financial situations and financial technology which is one of the most dynamic sectors of our time, to every individual and business, from the most remote corner of the country to the most central parts.</p>
                        </Col>
                    </Row>
                </div>
                <Row className="ant-row--swiper">
                    <Col>
                        <Swiper
                            className="swiper--team-members"
                            spaceBetween={22}
                            slidesPerView="auto"
                            slidesOffsetBefore={60}
                            slidesOffsetAfter={60}
                            pagination={{
                                type: "progressbar",
                                el: `#swiper-teams-pagination`
                            }}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            breakpoints={{
                                1248: {
                                    slidesOffsetBefore: 240,
                                },
                                768: {
                                    slidesOffsetBefore: 100,
                                }
                            }}
                        >
                            { TeamMembers.map( (item, key) => (
                                <SwiperSlide key={`team-members-slide-${key}`}>
                                    <Person image={item.image} name={item.name} title={item.title} />
                                </SwiperSlide>
                            )) }
                        </Swiper>
                    </Col>
                </Row>
                <div className="container">
                    <div id="swiper-teams-pagination" className="swiper__pagination"></div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default AboutPage;