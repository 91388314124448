import React from 'react'
import {Modal} from 'antd';

const QRModal = ({isModalOpen, setIsModalOpen}) => {
    return (
        <>
            <Modal open={isModalOpen}
                   className="ant-modal--qr"
                   centered={true}
                   onCancel={() => {setIsModalOpen(false)}}
                   closeIcon={<i className='icon-close'></i>}
                   footer={false}>
                <h2 className='text--h4 text--dark'>Get the Papel App</h2>
                <span className='text--p text--dark'>Scan the QR code to download the app</span>
                <figure>
                    <img src='images/store-qr.png' alt='qr code example'/>
                </figure>
                <span className='text--p text--dark'>or visit your app market to download.</span>
            </Modal>


        </>
    )
}

export default QRModal