import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { motion } from "framer-motion";

// import {ImageCircle} from "../index";

const ChatMessage = ({ avatar = 'dummy-image.png', messages, recipient, type, size, ...props }) => {
    return (
        <motion.div className={classNames('chat-message',
            {
                'chat-message--recipient': recipient,
                'chat-message--yellow': type === "yellow",
                'chat-message--pink': type === "pink",
                'chat-message--aqua': type === "aqua",
                'chat-message--green': type === "green",
                'chat-message--gray': type === "gray",
                'chat-message--small': size === "small",
                'chat-message--multi': messages.length > 1,
            }
        )} {...props}>
            {avatar && !recipient && <motion.figure className="chat-message__avatar">
                {avatar}
            </motion.figure>}
            <div className="chat-message__item-wrapper">
                {messages && messages.map((item) => (
                    <motion.div className="chat-message__item">
                        <span>{item}</span>
                    </motion.div>
                ))}
            </div>
        </motion.div>
    )
}

ChatMessage.propTypes = {
    messages: PropTypes.array,
    avatar: PropTypes.string,
    recipient: PropTypes.bool
}

export default ChatMessage;