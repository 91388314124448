import { Col, Row } from "antd";
import { motion } from "framer-motion";
import { useState } from "react";
import classNames from "classnames";
import { Footer, Header, PriceBox, ProductBox, SectionContact, SectionFlights, SectionQuestion, ChatMessage } from "../../components";
import VerticalTextScroll from "../../utilities/Functions/verticalTextScroll";

import {
    globalVariants, phoneTextVariants,
    phoneTextEvenVariants, rotateXVariants, chatMessageVariants,
    textVariants, imagesVariants, productBoxContainerVariants,
} from "../../utilities/Config";
import './personal.scss';


const Personal = () => {
    const verticalTextAnimation = VerticalTextScroll();

    const [sendReceiveActive, setSendReceiveActive] = useState(true);
    const [requestMoneyActive, setRequestMoneyActive] = useState(false);
    const [chatActive, setChatActive] = useState(false);
    const [borderline, setBorderLine] = useState("0");

    const sendReceiveOnClick = () => {
        setSendReceiveActive(true);
        setRequestMoneyActive(false);
        setChatActive(false);
        setBorderLine("0");
    }

    const requestMoneyOnClick = () => {
        setSendReceiveActive(false);
        setRequestMoneyActive(true);
        setChatActive(false);
        setBorderLine("33%");
    }
    const chatOnClick = () => {
        setSendReceiveActive(false);
        setRequestMoneyActive(false);
        setChatActive(true);
        setBorderLine("66%");
    }

    return (
        <>
            <Header />
            <section className="section-personal">
                <motion.div className="container">
                    <div>
                        <motion.h2 variants={phoneTextVariants} initial="hidden" animate="whileInView" className="text--h2">One app for all your financial <span className="text--gradient-pink">needs.</span></motion.h2>
                        <motion.div variants={globalVariants}
                            initial="hidden"
                            animate="whileInView"
                            className="price-box-wrapper">
                            <PriceBox variants={phoneTextVariants} type="green" icon="shopping-bag-line" text="Shopping" />
                            <PriceBox variants={phoneTextVariants} />
                            <PriceBox variants={phoneTextVariants} type="pink" icon="t-shirt-line" text="Clothes" price="$ 120,61" />
                        </motion.div>
                    </div>
                    <motion.figure variants={textVariants} initial="hidden" animate="visible">
                        <img src="images/personal-landing-1.png"
                            srcSet="images/personal-landing-1-2x.png 2x, images/personal-landing-1.png 1x" alt="Phone" />
                    </motion.figure>
                </motion.div>
            </section>
            <section className="section-daily-transaction">
                <div className="text-daily-transaction">
                <motion.span className="text--xl" style={{ x: verticalTextAnimation }}>
                    transactions
                    <figure>
                        <img src='images/transfer.png' srcSet="images/transfer-2x.png 2x, images/transfer.png 1x" alt="transfer" />
                    </figure>
                    daily transactions
                    transactions
                    <figure>
                        <img src='images/transfer.png' srcSet="images/transfer-2x.png 2x, images/transfer.png 1x" alt="transfer" />
                    </figure>
                    daily transactions
                    transactions
                    <figure>
                        <img src='images/transfer.png' srcSet="images/transfer-2x.png 2x, images/transfer.png 1x" alt="transfer" />
                    </figure>
                    daily transactions
                    transactions
                    <figure>
                        <img src='images/transfer.png' srcSet="images/transfer-2x.png 2x, images/transfer.png 1x" alt="transfer" />
                    </figure>
                    daily transactions
                </motion.span>
                </div>
                <motion.figure variants={textVariants} initial="hidden" animate="visible">
                    <img src="images/personal-landing-2.png"
                        srcSet="images/personal-landing-2-2x.png 2x, images/personal-landing-2.png 1x" alt="Phone" />
                </motion.figure>
            </section>
            <section className="section-business dark">
                <div className="container">
                    <Row className="section-business__animation" gutter={24}>
                        <Col className="border-effect" md={{ span: 10, offset: 2 }} span={24}>
                            <div className="border-effect__line" style={{ top: borderline }}></div>
                            <div
                                className={classNames("section-business__animation__item",
                                    { "active": sendReceiveActive })}
                                onClick={sendReceiveOnClick}>
                                <h3 className="text--h3">Send & Receive</h3>
                                {sendReceiveActive && <motion.div variants={productBoxContainerVariants}
                                    initial="hidden"
                                    whileInView="whileInView"
                                    viewport={{ once: true, amount: .1 }}>
                                    <p className="text--p">You can easily send and receive money securely and instantly.</p>
                                </motion.div>}
                            </div>
                            <motion.div variants={productBoxContainerVariants}
                                className={classNames("section-business__animation__item",
                                    { "active": requestMoneyActive })}
                                onClick={requestMoneyOnClick}>
                                <h3 className="text--h3">Request Money</h3>
                                {requestMoneyActive && <motion.div variants={productBoxContainerVariants}
                                    initial="hidden"
                                    whileInView="whileInView"
                                    viewport={{ once: true, amount: .1 }}>
                                    <p className="text--p">Requesting money becomes a convenient process, allowing for a quick and efficient way to settle transactions.</p>
                                </motion.div>}
                            </motion.div>
                            <div className={classNames("section-business__animation__item",
                                { "active": chatActive })}
                                onClick={chatOnClick}>
                                <h3 className="text--h3">Chat</h3>
                                {chatActive && <motion.div variants={productBoxContainerVariants}
                                    initial="hidden"
                                    whileInView="whileInView"
                                    viewport={{ once: true, amount: .1 }}>
                                    <p className="text--p">Effortlessly manage expenses among friends and colleagues.</p>
                                </motion.div>}
                            </div>
                        </Col>
                        {sendReceiveActive && <motion.Col variants={globalVariants}
                            initial="hidden"
                            animate="whileInView"
                            viewport={{ once: true, amount: .1 }} className="send-receive" md={12}>

                            <motion.figure
                                variants={imagesVariants}>
                                <img src="images/sendReceivePhone.png"
                                    srcSet="images/sendReceivePhone-2x.png 2x, images/sendReceivePhone.png 1x"
                                    alt="Phone" />
                            </motion.figure>
                            <div className="send-receive-avatar">
                                <ChatMessage variants={chatMessageVariants}
                                    type="gray"
                                    avatar={<img src="images/sendReceiveAvatar.png"
                                        srcSet="images/sendReceiveAvatar-2x.png 2x, images/sendReceiveAvatar.png 1x"
                                        alt="avatar" />}
                                    messages={["I am old, this paper says IBAN"]} />
                                <ChatMessage variants={chatMessageVariants} type="purple" recipient={true}
                                    messages={["no problem", "I got it easy😍😘🥲"]} />
                            </div>
                            <motion.figure className="send-receive-arrow"
                                variants={imagesVariants}>
                                <img src="images/arrow.png"
                                    srcSet="images/arrow-2x.png 2x, images/arrow.png 1x"
                                    alt="arrow" />
                            </motion.figure>
                            <motion.figure className="send-receive-iban"
                                variants={imagesVariants}>
                                <img src="images/sendReceiveIban.png"
                                    srcSet="images/sendReceiveIban-2x.png 2x, images/sendReceiveIban.png 1x"
                                    alt="message" />
                            </motion.figure>
                        </motion.Col>}
                        {chatActive && <motion.Col variants={globalVariants}
                            initial="hidden"
                            animate="whileInView"
                            viewport={{ once: true, amount: .1 }} className="request-money" md={12}>
                            <motion.figure
                                variants={rotateXVariants}>
                                <img src="images/request-money.png"
                                    srcSet="images/request-money-2x.png 2x, images/request-money.png 1x"
                                    alt="request-money" />
                            </motion.figure>
                            <motion.figure className="request-money-content"
                                variants={phoneTextEvenVariants}>
                                <img src="images/request-money-content.png"
                                    srcSet="images/request-money-content.png 2x, images/request-money-content.png 1x"
                                    alt="request-money" />
                            </motion.figure>
                        </motion.Col>}
                        {requestMoneyActive && <motion.Col variants={globalVariants}
                            initial="hidden"
                            animate="whileInView"
                            viewport={{ once: true, amount: .1 }} className="request-money" md={12}>
                            <motion.figure
                                variants={rotateXVariants}>
                                <img src="images/request-money.png"
                                    srcSet="images/request-money-2x.png 2x, images/request-money.png 1x"
                                    alt="request-money" />
                            </motion.figure>
                            <motion.figure className="request-money-content"
                                variants={phoneTextEvenVariants}>
                                <img src="images/request-money-content.png"
                                    srcSet="images/request-money-content.png 2x, images/request-money-content.png 1x"
                                    alt="request-money" />
                            </motion.figure>
                        </motion.Col>}
                    </Row>
                    <Row className="section-business__for-you" gutter={24}>
                        <Col span={24}>
                            <motion.h2 variants={textVariants}
                                initial='hidden'
                                whileInView="visible"
                                viewport={{ once: true, amount: .1 }}
                                className="text--h1">Papel for <span className="text--gradient-pink">you.</span>
                            </motion.h2>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="section-money">
                <motion.div variants={productBoxContainerVariants}
                    initial="hidden"
                    viewport={{ once: true, amount: .1 }}
                    whileInView="whileInView" className="container">
                    <motion.h2 variants={phoneTextVariants} className="text--h3 text--dark">We make money management easy and <span className="text--gradient-v4">effective.</span></motion.h2>
                    <motion.p variants={phoneTextVariants} className="text--h4 text--dark">At Papel, we understand the importance of security. That's why we prioritize protecting your personal information and finances when you use our financial applications. Rest assured that your security is our top concern.</motion.p>

                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <ProductBox
                                type={false}
                                className="product-box--spent"
                                title={<span className="text--h3 text--dark">Spent it well</span>}
                                image={<figure><img src="images/spent-it.png" srcSet="images/spent-it-2x.png 2x, images/spent-it.png 1x" alt="product spent" /></figure>}
                                icons={[]}>
                            </ProductBox>
                        </Col>
                        <Col md={12} span={24}>
                            <ProductBox compType="custom"
                                type={false}
                                className="product-box--money"
                                title={<span className="text--h3 text--white">Fast and reliable service, every time.</span>}
                                image={<figure><img src="images/dollar-eur.png" srcSet="images/dollar-eur-2x.png 2x, images/dollar-eur.png 1x" alt="product exclusive" /></figure>}
                                icons={[]}>
                            </ProductBox>
                        </Col>
                        <Col md={12} span={24}>
                            <ProductBox type={false}
                                className="product-box--abroad"
                                title={<span className="text--h3 text--dark">Spend abroad and <span className="text--gradient-v4">earn</span></span>}
                                image={<figure><img src="images/abroad.png" srcSet="images/abroad-2x.png 2x, images/abroad.png 1x" alt="product abroad" /></figure>}
                                icons={[]}>
                            </ProductBox>
                        </Col>
                    </Row>
                </motion.div>
            </section>
            <SectionFlights />
            <SectionQuestion />
            <SectionContact />
            <Footer />
        </>
    )
}

export default Personal;