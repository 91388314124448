const Questions = [
    {
        title: "What is “Papel” ?",
        content: <p className="text--caption">Papel is a payment system which unites all digital wallet infrastructure, payment with or without card, bank transmittence and other alternative payments under one roof. Papel has received its electronic money issuance license within the scope of law no.6493. Our company is operating in the financial technologies industry under the Central Bank of the Republic of Turkey like all Payment Institutions and Electronic Money companies.</p>,
        key: 'question-1'
    },
    {
        title: "Who does 'Papel'serve?",
        content: <p className="text--caption">Everyone who is older than 13 can use Papel digital wallet. Natural and legal persons over the age of 18 can benefit from our payment services.</p>,
        key: 'question-2'
    },
    {
        title: "Is it paid to use 'Papel'?",
        content: <p className="text--caption">You can subscribe to Papel free. We have no registration fee, setup fee, inefficiency fee, monthly or annual fee.</p>,
        key: 'question-3'
    },
    {
        title: "Which bank cards can I use in Papel?",
        content: <p className="text--caption">You can make transactions with all credit cards at Papel.</p>,
        key: 'question-4'
    },
]
const MenuLinks = [
    {
        title: 'PERSONAL',
        href: '/personal'
    },
    {
        title: 'BUSINESS',
        href: '/business'
    },
    {
        title: 'GOLD CARD',
        href: '/gold-card'
    },
    {
        title: 'CARDS',
        href: '/cards'
    },
    // {
    //     title: 'FEES',
    //     href: '/fees-limits'
    // },
    // {
    //     title: 'SECURITY',
    //     href: '/security'
    // },
    // {
    //     title: 'HELP',
    //     href: '/help'
    // },
]

const HelpBoxes = [
    {
        image: 'images/box-payment.png',
        title: 'Payment'
    },
    {
        image: 'images/box-papel-card.png',
        title: 'Papel Card'
    },
    {
        image: 'images/box-cashback.png',
        title: 'Cashback'
    },
    {
        image: 'images/box-account.png',
        title: 'Account'
    },
    {
        image: 'images/box-transfer.png',
        title: 'Transfer'
    },
    {
        image: 'images/box-papel-card.png',
        title: 'Business'
    }
]

const TeamMembers = [
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
]

const SequenceImages = [
    'images/sequence/HeroCards_00000.png',
    'images/sequence/HeroCards_00001.png',
    'images/sequence/HeroCards_00002.png',
    'images/sequence/HeroCards_00003.png',
    'images/sequence/HeroCards_00004.png',
    'images/sequence/HeroCards_00005.png',
    'images/sequence/HeroCards_00006.png',
    'images/sequence/HeroCards_00007.png',
    'images/sequence/HeroCards_00008.png',
    'images/sequence/HeroCards_00009.png',
    'images/sequence/HeroCards_00010.png',
    'images/sequence/HeroCards_00011.png',
    'images/sequence/HeroCards_00012.png',
    'images/sequence/HeroCards_00013.png',
    'images/sequence/HeroCards_00014.png',
    'images/sequence/HeroCards_00015.png',
    'images/sequence/HeroCards_00016.png',
    'images/sequence/HeroCards_00017.png',
    'images/sequence/HeroCards_00018.png',
    'images/sequence/HeroCards_00019.png',
    'images/sequence/HeroCards_00020.png',
    'images/sequence/HeroCards_00021.png',
    'images/sequence/HeroCards_00022.png',
    'images/sequence/HeroCards_00023.png',
    'images/sequence/HeroCards_00024.png',
    'images/sequence/HeroCards_00025.png',
    'images/sequence/HeroCards_00026.png',
    'images/sequence/HeroCards_00027.png',
    'images/sequence/HeroCards_00028.png',
    'images/sequence/HeroCards_00029.png',
    'images/sequence/HeroCards_00030.png',
    'images/sequence/HeroCards_00031.png',
    'images/sequence/HeroCards_00032.png',
    'images/sequence/HeroCards_00033.png',
    'images/sequence/HeroCards_00034.png',
    'images/sequence/HeroCards_00035.png',
    'images/sequence/HeroCards_00036.png',
    'images/sequence/HeroCards_00037.png',
    'images/sequence/HeroCards_00038.png',
    'images/sequence/HeroCards_00039.png',
    'images/sequence/HeroCards_00040.png',
    'images/sequence/HeroCards_00041.png',
    'images/sequence/HeroCards_00042.png',
    'images/sequence/HeroCards_00043.png',
    'images/sequence/HeroCards_00044.png',
    'images/sequence/HeroCards_00045.png',
    'images/sequence/HeroCards_00046.png',
    'images/sequence/HeroCards_00047.png',
    'images/sequence/HeroCards_00048.png',
    'images/sequence/HeroCards_00049.png',
    'images/sequence/HeroCards_00050.png',
    'images/sequence/HeroCards_00051.png',
    'images/sequence/HeroCards_00052.png',
    'images/sequence/HeroCards_00053.png',
    'images/sequence/HeroCards_00054.png',
    'images/sequence/HeroCards_00055.png',
    'images/sequence/HeroCards_00056.png',
    'images/sequence/HeroCards_00057.png',
    'images/sequence/HeroCards_00058.png',
    'images/sequence/HeroCards_00059.png',
    'images/sequence/HeroCards_00060.png',
    'images/sequence/HeroCards_00061.png',
    'images/sequence/HeroCards_00062.png',
    'images/sequence/HeroCards_00063.png',
    'images/sequence/HeroCards_00064.png',
    'images/sequence/HeroCards_00065.png',
    'images/sequence/HeroCards_00066.png',
    'images/sequence/HeroCards_00067.png',
    'images/sequence/HeroCards_00068.png',
    'images/sequence/HeroCards_00069.png',
    'images/sequence/HeroCards_00070.png',
    'images/sequence/HeroCards_00071.png',
    'images/sequence/HeroCards_00072.png',
    'images/sequence/HeroCards_00073.png',
    'images/sequence/HeroCards_00074.png',
    'images/sequence/HeroCards_00075.png',
    'images/sequence/HeroCards_00076.png',
    'images/sequence/HeroCards_00077.png',
    'images/sequence/HeroCards_00078.png',
    'images/sequence/HeroCards_00079.png',
    'images/sequence/HeroCards_00080.png',
    'images/sequence/HeroCards_00081.png',
    'images/sequence/HeroCards_00082.png',
    'images/sequence/HeroCards_00083.png',
    'images/sequence/HeroCards_00084.png',
    'images/sequence/HeroCards_00085.png',
    'images/sequence/HeroCards_00086.png',
    'images/sequence/HeroCards_00087.png',
    'images/sequence/HeroCards_00088.png',
    'images/sequence/HeroCards_00089.png',
    'images/sequence/HeroCards_00090.png',
    'images/sequence/HeroCards_00091.png',
    'images/sequence/HeroCards_00092.png',
    'images/sequence/HeroCards_00093.png',
    'images/sequence/HeroCards_00094.png',
    'images/sequence/HeroCards_00095.png',
    'images/sequence/HeroCards_00096.png',
    'images/sequence/HeroCards_00097.png',
    'images/sequence/HeroCards_00098.png',
    'images/sequence/HeroCards_00099.png',
    'images/sequence/HeroCards_00100.png',
    'images/sequence/HeroCards_00101.png',
    'images/sequence/HeroCards_00102.png',
    'images/sequence/HeroCards_00103.png',
    'images/sequence/HeroCards_00104.png',
    'images/sequence/HeroCards_00105.png',
    'images/sequence/HeroCards_00106.png',
    'images/sequence/HeroCards_00107.png',
    'images/sequence/HeroCards_00108.png',
    'images/sequence/HeroCards_00109.png',
    'images/sequence/HeroCards_00110.png',
    'images/sequence/HeroCards_00111.png',
    'images/sequence/HeroCards_00112.png',
    'images/sequence/HeroCards_00113.png',
    'images/sequence/HeroCards_00114.png',
    'images/sequence/HeroCards_00115.png',
    'images/sequence/HeroCards_00116.png',
    'images/sequence/HeroCards_00117.png',
    'images/sequence/HeroCards_00118.png',
    'images/sequence/HeroCards_00119.png',
    'images/sequence/HeroCards_00120.png',
    'images/sequence/HeroCards_00121.png',
    'images/sequence/HeroCards_00122.png',
    'images/sequence/HeroCards_00123.png',
    'images/sequence/HeroCards_00124.png',
    'images/sequence/HeroCards_00125.png',
    'images/sequence/HeroCards_00126.png',
    'images/sequence/HeroCards_00127.png',
    'images/sequence/HeroCards_00128.png',
    'images/sequence/HeroCards_00129.png',
    'images/sequence/HeroCards_00130.png',
    'images/sequence/HeroCards_00131.png',
    'images/sequence/HeroCards_00132.png',
    'images/sequence/HeroCards_00133.png',
    'images/sequence/HeroCards_00134.png',
    'images/sequence/HeroCards_00135.png',
    'images/sequence/HeroCards_00136.png',
    'images/sequence/HeroCards_00137.png',
    'images/sequence/HeroCards_00138.png',
    'images/sequence/HeroCards_00139.png',
    'images/sequence/HeroCards_00140.png',
    'images/sequence/HeroCards_00141.png',
    'images/sequence/HeroCards_00142.png',
    'images/sequence/HeroCards_00143.png',
    'images/sequence/HeroCards_00144.png',
    'images/sequence/HeroCards_00145.png',
    'images/sequence/HeroCards_00146.png',
    'images/sequence/HeroCards_00147.png',
    'images/sequence/HeroCards_00148.png',
    'images/sequence/HeroCards_00149.png',
    'images/sequence/HeroCards_00150.png',
    'images/sequence/HeroCards_00151.png',
    'images/sequence/HeroCards_00152.png',
    'images/sequence/HeroCards_00153.png',
    'images/sequence/HeroCards_00154.png',
    'images/sequence/HeroCards_00155.png',
    'images/sequence/HeroCards_00156.png',
    'images/sequence/HeroCards_00157.png',
    'images/sequence/HeroCards_00158.png',
    'images/sequence/HeroCards_00159.png',
    'images/sequence/HeroCards_00160.png',
    'images/sequence/HeroCards_00161.png',
    'images/sequence/HeroCards_00162.png',
    'images/sequence/HeroCards_00163.png',
    'images/sequence/HeroCards_00164.png',
    'images/sequence/HeroCards_00165.png',
    'images/sequence/HeroCards_00166.png',
    'images/sequence/HeroCards_00167.png',
    'images/sequence/HeroCards_00168.png',
    'images/sequence/HeroCards_00169.png',
    'images/sequence/HeroCards_00170.png',
    'images/sequence/HeroCards_00171.png',
    'images/sequence/HeroCards_00172.png',
    'images/sequence/HeroCards_00173.png',
    'images/sequence/HeroCards_00174.png',
    'images/sequence/HeroCards_00175.png',
    'images/sequence/HeroCards_00176.png',
    'images/sequence/HeroCards_00177.png',
    'images/sequence/HeroCards_00178.png',
    'images/sequence/HeroCards_00179.png',
    'images/sequence/HeroCards_00180.png',
    'images/sequence/HeroCards_00181.png',
    'images/sequence/HeroCards_00182.png',
    'images/sequence/HeroCards_00183.png',
    'images/sequence/HeroCards_00184.png',
    'images/sequence/HeroCards_00185.png',
    'images/sequence/HeroCards_00186.png',
    'images/sequence/HeroCards_00187.png',
    'images/sequence/HeroCards_00188.png',
    'images/sequence/HeroCards_00189.png',
    'images/sequence/HeroCards_00190.png',
    'images/sequence/HeroCards_00191.png',
    'images/sequence/HeroCards_00192.png',
    'images/sequence/HeroCards_00193.png',
    'images/sequence/HeroCards_00194.png',
    'images/sequence/HeroCards_00195.png',
    'images/sequence/HeroCards_00196.png',
    'images/sequence/HeroCards_00197.png',
    'images/sequence/HeroCards_00198.png',
    'images/sequence/HeroCards_00199.png',
    'images/sequence/HeroCards_00200.png',
    'images/sequence/HeroCards_00201.png',
    'images/sequence/HeroCards_00202.png',
    'images/sequence/HeroCards_00203.png',
    'images/sequence/HeroCards_00204.png',
    'images/sequence/HeroCards_00205.png',
    'images/sequence/HeroCards_00206.png',
    'images/sequence/HeroCards_00207.png',
    'images/sequence/HeroCards_00208.png',
    'images/sequence/HeroCards_00209.png',
    'images/sequence/HeroCards_00210.png',
    'images/sequence/HeroCards_00211.png',
    'images/sequence/HeroCards_00212.png',

]

export { Questions, MenuLinks, HelpBoxes, TeamMembers, SequenceImages }