import {Row, Col} from "antd";
import {ProductBox} from "../../index";
import {motion} from "framer-motion";
import {
    globalVariants,
    phoneTextEvenVariants,
    phoneTextVariants,
    productBoxContainerVariants,
    textVariants,
    imagesVariants,
} from "../../../utilities/Config";
import {Timeline, Tween} from "react-gsap";
import {Controller, Scene} from "react-scrollmagic";

const SectionFinancial = () => {
    return (
        <section className="section-financial">
            <div className="container">
                <Controller>
                    <Scene
                        triggerHook="onLeaveBack"
                        classToggle="scroll-magic"
                        duration={5000}
                        pin
                    >
                        {(progress) => (
                            <div className="sticky">
                                <Timeline
                                    wrapper={<div className="pinContainer"/>}
                                    totalProgress={progress}
                                    paused
                                >
                                    <Tween>
                                        <div className="test">
                                            <div>
                                                <Timeline
                                                    target={
                                                        <h3 className="text--h3 text--dark section__title">
                                                            Choose<span className="text--gradient-v4"> Papel </span>
                                                            for Fast, Secure Financial Results!
                                                        </h3>
                                                    }
                                                >
                                                    <Tween
                                                        from={{opacity: 0, x: "-200px"}}
                                                        to={{opacity: 1, x: "0"}}
                                                    />
                                                </Timeline>
                                                <Timeline
                                                    target={
                                                        <p className="text--h5 text--dark">
                                                            At Papel, we understand the importance of
                                                            security. That's why we prioritize protecting your
                                                            personal information and finances when you use our
                                                            financial applications. Rest assured that your
                                                            security is our top concern.
                                                        </p>
                                                    }
                                                >
                                                    <Tween
                                                        from={{opacity: 0, x: "200px"}}
                                                        to={{opacity: 1, x: "0"}}
                                                    />
                                                </Timeline>
                                            </div>
                                        </div>
                                    </Tween>
                                </Timeline>
                            </div>
                        )}
                    </Scene>
                    <Scene
                        triggerHook="onLeave"
                        classToggle="scroll-magic"
                        duration={5000}
                        pin>
                        {(progress) => (
                            <div>
                                <Timeline wrapper={<div className="pinContainer2"/>} totalProgress={progress} paused>
                                    <Tween>
                                        <div className="test">
                                            <div>
                                                <Row gutter={[24, {lg: 96, md: 24, sm: 24, xs: 24}]}>
                                                    <Col md={12}>
                                                        <Timeline>
                                                            <Tween
                                                                from={{opacity: 0, x: '-200px', y: '20px'}}
                                                                to={{opacity: 1, x: '0', y: '20px'}}
                                                            >
                                                                <div>
                                                                    <ProductBox type={false}
                                                                                icons={[]}
                                                                                className="product-box--fast"
                                                                                image={<figure><img
                                                                                    src="images/product-fast.png"
                                                                                    srcSet="images/product-fast-2x.png 2x, images/product-fast.png 1x"
                                                                                    alt="product fast"/></figure>}
                                                                                title={<><span
                                                                                    className="text--gradient-v4">Fast and reliable</span> service,
                                                                                    every time.</>}>
                                                                    </ProductBox>
                                                                </div>
                                                            </Tween>
                                                        </Timeline>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Timeline>
                                                            <Tween
                                                                from={{opacity: 0, x: '100px', y: '20px'}}
                                                                to={{opacity: 1, x: '0', y: '20px'}}
                                                            >
                                                                <div>
                                                                    <ProductBox type={false}
                                                                                boxType="custom"
                                                                                icons={[]}
                                                                                className="product-box--secure"
                                                                                image={<figure><img
                                                                                    src="images/product-secure.png"
                                                                                    srcSet="images/product-secure-2x.png 2x, images/product-secure.png 1x"
                                                                                    alt="product secure"/></figure>}
                                                                                title={<><span
                                                                                    className="text--gradient-v4">Secure</span> with
                                                                                    us, every step of the way.</>}>
                                                                    </ProductBox>
                                                                </div>
                                                            </Tween>
                                                        </Timeline>

                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </Tween>
                                </Timeline>

                            </div>
                        )}
                    </Scene>
                    <Scene
                        classToggle="scroll-magic"
                        duration={5000}
                    >
                        {progress => (
                            <div className="sticky">
                                <Timeline wrapper={<div className="pinContainer2"/>} totalProgress={progress} paused>
                                    <Tween>
                                        <div className="test">
                                            <div>
                                                <Col md={24}>
                                                    <Timeline>
                                                        <Tween
                                                            from={{y: '60px'}}
                                                        >
                                                            <div className="product-box--pricing">
                                                                <div className="product-box__left">
                                                                    <div className="text--h3 text--dark">Pricing that
                                                                        works for you.
                                                                    </div>
                                                                </div>
                                                                <div className="product-box__right">
                                                                    <div>
                                                                        <div className="text--h6 text--dark">Get started
                                                                            free.
                                                                        </div>
                                                                        <p className="text--caption text--dark">Start
                                                                            any of our paid software products free for
                                                                            30 days.</p>
                                                                    </div>
                                                                    <div>
                                                                        <div className="text--h6 text--dark">Pay the
                                                                            same rate for every major card.
                                                                        </div>
                                                                        <p className="text--caption text--dark">Clear
                                                                            transaction rates for all types of payments,
                                                                            whether in person, online, or card on
                                                                            file.</p>
                                                                    </div>
                                                                    <div>
                                                                        <div className="text--h6 text--dark">No hidden
                                                                            fees or long-term contracts.
                                                                        </div>
                                                                        <p className="text--caption text--dark">Skip the
                                                                            startup fees, authorization fees, statement
                                                                            fees, PCI-compliance fees, and more.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tween>
                                                    </Timeline>
                                                </Col>

                                            </div>
                                        </div>
                                    </Tween>
                                </Timeline>


                            </div>
                        )}
                    </Scene>

                </Controller>
            </div>
        </section>
    );
};

export default SectionFinancial;
