import PropTypes from "prop-types";
import classNames from "classnames";
import {motion} from "framer-motion";


const PriceBox = ({ icon='restaurant-fill', text = 'Restaurant', price = "$ 254,80", type, ...props }) => {
    return (
        <motion.div className={classNames('price-box',
            {
                'price-box--green': type === 'green',
                'price-box--pink': type === 'pink',
                'price-box--aqua': type === 'aqua',
            }
        )} {...props}>
            <i className={`price-box__icon icon-${icon}`}></i>
            <span className="price-box__text">{text}</span>
            <span className="price-box__price">{price}</span>
        </motion.div>
    )
}

PriceBox.propTypes = {
    icon: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    price: PropTypes.string
}

export default PriceBox;