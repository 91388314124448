import { Collapse } from "antd";
import PropTypes from "prop-types";
import classNames from "classnames";

const CollapseItem = ({ data, type }) => {
  const { Panel } = Collapse;

  const CollapseHeader = ({ title }) => {
    return (
      <>
        <div className="collapse__title text--subtitle">{title}</div>
        <button>
          <i className="icon-add"></i>
        </button>
      </>
    );
  };

  const arrayOfKeys = data && data.map((item) => item.key);

  return (
    <div
      className={classNames("collapse", { "collapse--dark": type === "dark" })}
    >
      <Collapse defaultActiveKey={arrayOfKeys}>
        {data &&
          data.map((item, index) => {
            return (
              <Panel
                showArrow={false}
                header={<CollapseHeader title={item.title} />}
                key={item.key}
              >
                <div>{item.content}</div>
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

CollapseItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default CollapseItem;
