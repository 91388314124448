import {useAnimationFrame, useMotionValue, useTransform} from "framer-motion";
import {wrap} from "@motionone/utils";
import {useRef} from "react";


export default function VerticalTextScroll() {
    const baseX = useMotionValue(0);
    const velocityFactor = useTransform(baseX, [0, 1000], [0, 5], {
        clamp: false
    });


    const x = useTransform(baseX, (v) => {
        return (
            `${wrap(-20, -45, v)}%`
        )
    });

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
        let moveBy = .05 ;

        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        baseX.set(baseX.get() + moveBy);
    });

    return x
}