import React from 'react';

const UserPortrait = () => {
    return (
        <div className="user-portrait">
            <div className="circle"></div>
            <div className="line"></div>
            <div className="line--second"></div>
        </div>
    )
}
export default UserPortrait;