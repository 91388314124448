import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Select as AntSelect} from "antd";
// import "./Select.scss";

const Select = ({title, children, className, data, prefixIcon,afterfixIcon, ...props}) => {
    const {Option} = AntSelect;

    return (
        <div className={classNames('select', className)}>
            { prefixIcon && <div className="select__prefix-icon">{prefixIcon}</div> }
            <AntSelect
                {...props}>
                {data && data.map((item, index) => {
                    return <Option key={index} value={item.value}>{item.label}</Option>
                })}
            </AntSelect>
            { afterfixIcon && <div className="select__afterfix-icon">{afterfixIcon}</div> }
        </div>
    );
};

Select.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    prefixIcon: PropTypes.element
};

Select.Option = AntSelect.Option;
export default Select;
